import { useSelector } from 'react-redux';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

import type { TAppState } from 'src/store';
import type { AlbumCover } from 'src/types';

const BillingPanel = () => {
  const {
    covers,
    albumConfiguration,
  }: {
    covers: AlbumCover[];
    albumConfiguration: any;
  } = useSelector((state: TAppState) => state.album);

  const totalPrice = covers.reduce((acc, cover) => acc + cover.price, 0);

  const advancePayment = albumConfiguration?.advancePayment;

  const { t } = useTranslation();

  const finalPrice = useMemo(() => {
    if (!advancePayment || advancePayment >= totalPrice) {
      return totalPrice;
    }

    return totalPrice - advancePayment;
  }, [totalPrice]);

  return (
    <div className={styles['total-price-container']}>
      <div className={styles['total-price-container__entries']}>
        {covers.sort((a, b) => a.order - b.order).map((cover: AlbumCover) => (
          <div key={cover.id} className={styles['total-price-container__entries__entry']}>
            <span>
              {cover.order + 1}
              .
              {' '}
              {cover.name || `${t('titleCaseName')} ${cover.order + 1}`}
            </span>
            <span>
              {cover.price}
              {' '}
              RON
            </span>
          </div>
        ))}
      </div>
      <span className={styles['total-price-container__subtotal-price']}>
        Avans:
        {' '}
        {advancePayment}
        RON
      </span>
      <span className={styles['total-price-container__total-price']}>
        Rest Plata:
        {' '}
        {finalPrice}
        {' '}
        RON
      </span>
    </div>
  );
};

export default BillingPanel;
