import { AlbumType } from 'src/types';
import { isCoverConfigured } from './isCoverConfigured';
import { isSectionConfigured } from './isSectionConfigured';

export const isAlbumConfigured = (album: AlbumType) => {
  const areCoversConfigured = album.covers.every((cover) => isCoverConfigured(cover));

  if (!areCoversConfigured) return false;

  const areSectionsConfigured = album.sections.every((section) => isSectionConfigured(section));

  return areSectionsConfigured;
};
