import cn from 'classnames';
import styles from './index.module.scss';

type Option = {
  label: string;
  value: any;
};

interface Props {
  selectedOption: Option;
  options: Option[];
  handleChangeSelectedOption: (o: Option) => void;
}

const Toggle = ({ selectedOption, options, handleChangeSelectedOption }: Props) => (
  <div className={styles.toggle}>
    {options.map((option) => (
      <div
        key={JSON.stringify(option.value)}
        className={cn([{
          [styles.toggle__option]: JSON.stringify(selectedOption.value)
            !== JSON.stringify(option.value),
          [styles['toggle__option--focused']]: JSON.stringify(selectedOption.value)
            === JSON.stringify(option.value),
        }])}
        onClick={() => handleChangeSelectedOption(option)}
      >
        <span>{option.label}</span>
      </div>
    ))}
  </div>
);

export default Toggle;
