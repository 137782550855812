import { useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import styles from './AlbumContent.module.scss';
import { ContentEditingArea } from './components';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Layout from '../../layout/Layout';
import ControlsSidebar from 'src/layout/components/ControlsSidebar';
import OverviewSidebar from 'src/layout/components/OverviewSidebar';
import { TAppState } from 'src/store';
import { useDispatch, useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { useNavigate, useParams } from 'react-router-dom';
import { updateAlbum } from 'src/store/album/actions';
import { mapAlbumFromFirestoreFormat } from 'src/utils/mapAlbumFromFirestoreFormat';
import { ToastContainer } from 'react-toastify';
import { useTranslation } from 'react-i18next';

interface Props {
  handleSectionScroll: (id: string) => void;
}

const AlbumContent = ({ handleSectionScroll }: Props) => {
  const { albumId = '' } = useParams();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  useFirestoreConnect([{
    collection: 'albums',
  }]);

  const { t } = useTranslation();

  const {
    selectedView: {
      selectedView,
    },
    firestore: {
      data: {
        albums,
      },
      status: {
        requesting: {
          albums: isLoadingAlbums,
        },
      },
    },
  } = useSelector((state: TAppState) => state);

  if (!albumId) {
    navigate('/');
  }

  useEffect(() => {
    if (isLoadingAlbums || !albums || !albumId) return;

    if (!albums[albumId]) return;

    const mappedAlbum = mapAlbumFromFirestoreFormat(albums[albumId]);

    dispatch(updateAlbum(mappedAlbum));
  }, [isLoadingAlbums, albums, albumId]);

  return (
    <Layout hideOptions={false}>
      <DndProvider backend={HTML5Backend}>
        <div className={styles['layout-content-container']}>
          <ControlsSidebar />
          <main className={styles['content-container']}>
            {!albums || isLoadingAlbums ? (
              <p>{t('loadingText')}</p>
            ) : (
              <ContentEditingArea selectedView={selectedView} />
            )}
          </main>
          <OverviewSidebar handleSectionScroll={handleSectionScroll} />
        </div>
      </DndProvider>
      <ToastContainer />
    </Layout>
  );
};

export default AlbumContent;
