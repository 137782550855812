import { useSelector } from 'react-redux';
import { SectionWithText } from './components';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';

import { LeatherCoverSection, Section } from './components/SectionWithText/components';

import type { TAppState } from '../../../../../store';
import type { AlbumCover, AlbumSection } from 'src/types';

const Minimap = ({ handleSectionScroll }: any) => {
  const {
    album: {
      sections,
      covers,
    },
    selectedView: {
      selectedView,
    },
  } = useSelector((state: TAppState) => state);

  const { t } = useTranslation();

  return (
    <div className={styles['minimap-container']}>
      {selectedView === 'album-covers'
        ? (
          covers
            .filter(({ empty }: AlbumCover) => !empty)
            .sort((a: AlbumCover, b: AlbumCover) => a.order - b.order)
            .map(({
              id,
              template,
              regions = [],
              name,
              order,
              coverType,
              albumSize,
              photoInsertConfiguration,
            }: AlbumCover) => (
              coverType.value === 'leather-cover'
                ? (
                  <SectionWithText
                    key={id}
                    text={`${t('titleCaseCoverText')} - ${name || `${t('titleCaseName')} ${order + 1}`}`}
                  >
                    <LeatherCoverSection
                      albumSize={albumSize}
                      firebaseImageUrl={template}
                      photoInsertConfiguration={photoInsertConfiguration}
                    />
                  </SectionWithText>
                ) : (
                  <SectionWithText
                    key={id}
                    text={`${t('titleCaseCoverText')} - ${name || `${t('titleCaseName')} ${order + 1}`}`}
                  >
                    <Section
                      id={id}
                      image={template}
                      sectionImages={regions}
                      handleSectionScroll={handleSectionScroll}
                    />
                  </SectionWithText>
                )
            ))
        ) : (
          sections
            .filter(({ empty }: any) => !empty)
            .sort((a: AlbumSection, b: AlbumSection) => a.order - b.order)
            .map(({ id, template, regions = [] }: AlbumSection, index: number) => (
              <SectionWithText
                key={id}
                text={`${t('titleCaseSectionPagesCountText')} ${(index + 1) * 2 - 1} - ${(index + 1) * 2}`}
              >
                <Section
                  id={id}
                  image={template}
                  sectionImages={regions}
                  handleSectionScroll={handleSectionScroll}
                />
              </SectionWithText>
            ))
        )}
    </div>
  );
};

export default Minimap;
