import {
  lazy,
  Suspense,
  useState,
  useMemo,
} from 'react';
import { Toggle } from 'src/components';
import { AlbumInformation } from './components';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';

const Minimap = lazy(() => import('./components/Minimap'));
const BillingPanel = lazy(() => import('./components/BillingPanel'));

const OverviewSidebar = ({ handleSectionScroll }: any) => {
  const { t } = useTranslation();

  const panelViews = useMemo(() => ([
    {
      label: t('layout.overviewSidebar.panelViews.total'),
      value: 'total',
    },
    {
      label: t('layout.overviewSidebar.panelViews.templates'),
      value: 'template',
    },
  ]), [t]);

  const [activeView, setActiveView] = useState(panelViews[1]);

  return (
    <div className={styles['overview-sidebar']}>
      <Toggle
        options={panelViews}
        selectedOption={activeView}
        handleChangeSelectedOption={(o) => setActiveView(o)}
      />
      <Suspense fallback={<p>{t('loadingText')}</p>}>
        {activeView.value === 'template' ? (
          <Minimap handleSectionScroll={handleSectionScroll} />
        ) : (
          <BillingPanel />
        )}
      </Suspense>
      <AlbumInformation />
    </div>
  );
};

export default OverviewSidebar;
