/* eslint-disable max-len */
import { stripLocalImages } from './stripLocalImages';

import type { AlbumType, FirestoreAlbumType } from '../types';
import { mapAlbumConfigurationFromFirestore } from './mapAlbumConfigurationFromFirestore';

export const mapAlbumFromFirestoreFormat = (firestoreAlbum: FirestoreAlbumType): AlbumType => ({
  ...firestoreAlbum,
  sections: Object.values(firestoreAlbum.sections).map((section) => ({
    ...section,
    regions: section?.regions && Object.keys(section?.regions).length
      ? Object.values(section.regions).map((image) => stripLocalImages(image))
      : [],
  })),
  covers: Object.values(firestoreAlbum.covers).map((cover) => ({
    ...cover,
    regions: cover?.regions && Object.keys(cover?.regions).length
      ? Object.values(cover.regions).map((image) => stripLocalImages(image))
      : [],
  })),
  albumConfiguration: mapAlbumConfigurationFromFirestore(firestoreAlbum.albumConfiguration),
});
