import { useLayoutEffect, useState } from 'react';
import useOnResize from './useOnResize';
import { useGetParsedAspectRatio } from './useGetParsedAspectRatio';

const useAdjustContainerHeight = (
  ref: React.MutableRefObject<HTMLElement | null>,
  initialHeight?: number,
) => {
  const [computedHeight, setComputedHeight] = useState(initialHeight || 100);
  const parsedAspectRatio = useGetParsedAspectRatio();

  const adjustContainerHeight = () => {
    const containerWidth = ref.current?.offsetWidth;

    if (containerWidth) {
      setComputedHeight(containerWidth / parsedAspectRatio);
    }
  };

  useLayoutEffect(() => {
    adjustContainerHeight();
  }, []);

  useOnResize(adjustContainerHeight);

  return computedHeight;
};

export default useAdjustContainerHeight;
