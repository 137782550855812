import './App.scss';
import './index.scss';

import { useMemo } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import AlbumContent from './views/album-content/AlbumContent';
import Home from './views/home/Home';
import NotFound from './views/404';

import { useTranslation } from 'react-i18next';
import { useWindowSize } from './hooks/useWindowSize';
import { ScreenSizeIncompatibility } from './components';

import 'src/i18n';

const App = () => {
  const location = useLocation();

  const { t } = useTranslation();

  const title = useMemo(() => {
    const baseTitle = 'Studio adidume';

    if (location.pathname === '/') {
      return `${baseTitle} - ${t('home.welcomeHeader')}!`;
    }

    return `${baseTitle} - Configurator`;
  }, [location]);

  const handleSectionScroll = (id: string) => {
    document.querySelector(`[data-scroll-id='${id}']`)?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'start',
    });
  };

  const { width, height } = useWindowSize();

  const showAppContent = useMemo(() => (
    !!width && width >= 768
  ), [width, height]);

  return (
    <div className="App">
      <Helmet>
        <title>{title}</title>
        <meta name="author" content="Vlad Mihet" />
      </Helmet>
      {showAppContent ? (
        <Routes>
          <Route path="/" element={<Home />} />
          <Route
            path="/:albumId/edit-content"
            element={(
              <AlbumContent handleSectionScroll={handleSectionScroll} />
            )}
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      ) : (
        <ScreenSizeIncompatibility />
      )}
    </div>
  );
};

export default App;
