import Layout from 'src/layout/Layout';
import styles from './index.module.scss';

const ScreenSizeIncompatibility = () => (
  <Layout hideOptions>
    <div className={styles.container}>
      <div className={styles.container__content}>
        <h1>Se pare ca rezolutia dispozitivului tau este prea scazuta.</h1>
        <p>Te rugam sa revii de pe un dispozitiv desktop.</p>
      </div>
    </div>
  </Layout>
);

export default ScreenSizeIncompatibility;
