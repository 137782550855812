/* eslint-disable max-len */
import type {
  AlbumConfiguration,
  FirestoreAlbumConfiguration,
  FirestoreAlbumConfigurationAvailableDesign,
  FirestoreAlbumConfigurationAvailableSize,
  FirestoreAlbumConfigurationDiscountCode,
} from 'src/types';

/* eslint-disable no-param-reassign */
export const mapAlbumConfigurationToFirestore = (albumConfiguration: AlbumConfiguration): FirestoreAlbumConfiguration => {
  const obj: Partial<Pick<AlbumConfiguration, 'availableSizes' | 'availableDesigns' | 'discountCodes'>>
  & Omit<AlbumConfiguration, 'availableSizes' | 'availableDesigns' | 'discountCodes'> = { ...albumConfiguration };

  delete obj.availableSizes;
  delete obj.availableDesigns;
  delete obj.discountCodes;

  const availableSizes: { [availableSizeId: string]: FirestoreAlbumConfigurationAvailableSize } = {};
  const discountCodes: { [id: string]: FirestoreAlbumConfigurationDiscountCode } = {};
  const availableDesigns: { [availableDesignId: string]: FirestoreAlbumConfigurationAvailableDesign } = {};

  albumConfiguration?.availableSizes?.forEach((availableSize) => {
    const { myId } = availableSize;
    delete (availableSize as any).id;

    Object.assign(availableSizes, {
      ...availableSizes,
      [myId]: availableSize,
    });
  });

  albumConfiguration?.discountCodes?.forEach((discountCode) => {
    const { myId } = discountCode;
    delete (discountCode as any).id;

    Object.assign(discountCodes, {
      ...discountCodes,
      [myId]: discountCode,
    });
  });

  albumConfiguration?.availableDesigns?.forEach((availableDesign) => {
    const { myId } = availableDesign;
    delete (availableDesign as any).id;

    Object.assign(availableDesigns, {
      ...availableDesigns,
      [myId]: availableDesign,
    });
  });

  const mappedConfiguration = {
    ...obj,
    availableSizes,
    availableDesigns,
    discountCodes,
  };

  return mappedConfiguration;
};
