import type { AlbumConfigurationAvailableSize, PhotoInsertConfiguration } from 'src/types';

type InsertSizeAsPercentage = {
  width: number;
  height: number;
};

export const getInsertSizeAsPercentage = (
  albumSizeId: string,
  availableSizes: AlbumConfigurationAvailableSize[],
  insertImageConfiguration?: PhotoInsertConfiguration,
): InsertSizeAsPercentage => {
  const chosenSize = availableSizes.find(
    ((availableSize) => availableSize.myId === albumSizeId),
  );

  if (!chosenSize || !insertImageConfiguration?.size?.value) {
    return {
      width: 0,
      height: 0,
    };
  }

  const [insertWidth, insertHeight] = insertImageConfiguration.size.value.split('x');

  const { width, height } = chosenSize;

  return {
    width: (Number(insertWidth) * 100) / width,
    height: (Number(insertHeight) * 100) / height,
  };
};
