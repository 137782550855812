/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable react/jsx-props-no-spreading */
import {
  useState, useEffect, useCallback, useMemo,
} from 'react';
import { Button } from 'src/components';
import { storage } from '../../../firebase';
import { useDispatch, useSelector } from 'react-redux';
import { uploadImage } from '../../../store/uploadedImages/actions';
import { ref, uploadBytes } from 'firebase/storage';
import styles from './index.module.scss';
import { Gallery } from './components';
import { setSelectedView } from 'src/store/selectedView/actions';
import { useDropzone } from 'react-dropzone';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { toastConfiguration } from '../../../config';

import type { TAppState } from 'src/store';
import { toast } from 'react-toastify';

const baseStyle: any = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

const focusedStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

const minFileResolution = 1000;

const ControlsSidebar = () => {
  const [isUploadingImages, setIsUploadingImages] = useState(false);
  const [preparedImages, setPreparedImages] = useState<Array<File & { preview?: string }>>([]);

  const { selectedView } = useSelector((state: TAppState) => state.selectedView);

  const { t } = useTranslation();

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setPreparedImages(acceptedFiles.map((file) => Object.assign(file, {
      preview: URL.createObjectURL(file),
    })));
  }, []);

  // eslint-disable-next-line max-len, @typescript-eslint/no-unused-vars
  const minFileResolutionValidator = async (file: File): Promise<any> => new Promise((resolve, _reject) => {
    const image = new Image();

    image.addEventListener('load', () => {
      const fileWithResolution = {
        width: image.width,
        height: image.height,
        file,
      };

      resolve(fileWithResolution);
    });

    image.src = URL.createObjectURL(file);
  });

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpg', '.jpeg'],
    },
    onDrop,
    multiple: true,
    getFilesFromEvent: async (event: any) => {
      const promises: Promise<File>[] = [];
      const filesPromises: Promise<any>[] = [];

      event.forEach(async (handler: any) => {
        filesPromises.push(handler.getFile());
      });

      const files = await Promise.all(filesPromises);

      files.forEach((file: any) => {
        promises.push(minFileResolutionValidator(file));
      });

      const validatedFiles = await Promise.all(promises);

      return validatedFiles.map(({ file, width, height }: any) => Object.assign(file, {
        width,
        height,
      }));
    },
    validator: (file: any) => {
      if (file.width < minFileResolution || file.height < minFileResolution) {
        toast.error('Asigura-te ca imaginea/imaginile au rezolutia mai mare de 1000 de pixeli!', toastConfiguration);

        return {
          code: 'resolution-too-low',
          message: 'Image resolution must be greater than 1000px',
        };
      }

      return null;
    },
  });

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {}),
  }), [
    isFocused,
    isDragAccept,
    isDragReject,
  ]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (preparedImages.length < 1) return;

    const imagesToUploadAsPromises: Array<ReturnType<typeof uploadBytes>> = [];
    const imagesToUpload: Array<{
      imageUrl: string;
      fileName: string;
    }> = [];

    preparedImages.forEach((image) => {
      const imageUrl = URL.createObjectURL(image);

      const fileExtension = image.name.split('.')[1];

      const fileName = `${uuidv4()}.${fileExtension}`;

      const storageRef = ref(storage, fileName);

      imagesToUploadAsPromises.push(uploadBytes(storageRef, image));

      imagesToUpload.push({
        imageUrl,
        fileName,
      });
    });

    (async () => {
      try {
        setIsUploadingImages(true);
        await Promise.all(imagesToUploadAsPromises);

        imagesToUpload.forEach(({ imageUrl, fileName }) => {
          dispatch(uploadImage(imageUrl, fileName));
        });

        setIsUploadingImages(false);
      } catch (err) {
        console.error(err);
      } finally {
        setPreparedImages([]);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preparedImages]);

  useEffect(() => () => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    preparedImages.forEach((file) => {
      if (file.preview) URL.revokeObjectURL(file.preview);
    });
  }, []);

  return (
    <div className={styles['controls-sidebar']}>
      <Button
        filled={selectedView === 'album-covers'}
        handleClick={() => dispatch(setSelectedView('album-covers'))}
      >
        {t('layout.controlsSidebar.switch.covers')}
      </Button>
      <Button
        filled={selectedView === 'album-content'}
        handleClick={() => dispatch(setSelectedView('album-content'))}
      >
        {t('layout.controlsSidebar.switch.interior')}
      </Button>
      <div className={styles['gallery-section']}>
        <h2>{t('layout.controlsSidebar.galleryHeader')}</h2>
        <div
          {...getRootProps({
            className: cn([styles['gallery-section__add-image'], 'dropzone']),
            style,
          })}
        >
          <input {...getInputProps()} />
          {isUploadingImages ? (
            <p className={styles['loading-text']}>{t('layout.controlsSidebar.loadingText')}</p>
          ) : (
            <p>{t('layout.controlsSidebar.actions.addPhotos')}</p>
          )}
        </div>
        <Gallery />
      </div>
    </div>
  );
};

export default ControlsSidebar;
