export const getVerticalAlignmentAsFlexProperty = (val?: string) => {
  switch (val) {
    case 'top':
      return 'flex-start';
    case 'bottom':
      return 'flex-end';
    default:
      return 'center';
  }
};
