import { useSelector } from 'react-redux';
import { TAppState } from 'src/store';

export const useGetParsedAspectRatio = () => {
  const designAspectRatio = useSelector((state: TAppState) => state.album.albumDesign?.aspectRatio);

  const hasColonDelimiter = designAspectRatio.includes(':');

  if (hasColonDelimiter) {
    const [height, width] = designAspectRatio?.split(':') || [1, 2];

    return width / height;
  }

  const [height, width] = designAspectRatio?.split('x') || [1, 2];

  return width / height;
};
