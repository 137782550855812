/* eslint-disable react/no-danger */
import {
  useState, lazy, Suspense, useCallback, useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button } from 'src/components';
import { toastConfiguration } from 'src/config';
import { TAppState } from 'src/store';
import { checkForUnnamedAlbums } from 'src/utils/checkForUnnamedAlbums';
import { checkForZeroAlbumsCount } from 'src/utils/checkForZeroAlbumsCount';
import { isAlbumConfigured } from 'src/utils/isAlbumConfigured';
import { mapAlbumToFirestoreFormat } from 'src/utils/mapAlbumToFirestoreFormat';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ReactDOMServer from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
import { updateAlbum } from 'src/store/album/actions';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from 'src/firebase';
import { checkForAlbumsWithNoSize } from 'src/utils/checkForAlbumsWithNoSize';
import { sendAlbumOrderEmail } from 'src/firebase/functions';

const ConfirmationModal = lazy(() => import('src/components/ConfirmationModal'));

interface Props {
  hideOptions?: boolean;
}

const NewNavbar = ({ hideOptions = false }: Props) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const {
    album,
    album: {
      albumConfiguration: {
        finalMessage = '',
      } = {
        finalMessage: '',
      },
    },
  } = useSelector((state: TAppState) => state);

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const { albumId } = useParams();

  const handlePublishProgressToFirestore = useCallback(async () => {
    try {
      if (album.status?.ordered) {
        throw new Error('Albumul a fost deja comandat!');
      }

      const mappedAlbum = mapAlbumToFirestoreFormat({ ...album });

      // @ts-ignore
      const ref = doc(db, 'albums', albumId);

      await updateDoc(ref, {
        ...mappedAlbum,
        lastUpdatedAt: new Date().getTime(),
      });

      toast.success('Schimbarile au fost salvate cu succes!', toastConfiguration);
    } catch (err) {
      console.error(err);
      toast.error('A aparut o problema la salvarea schimbarilor. Incearca din nou mai tarziu.');
    }
  }, [album, albumId]);

  const handleOrderAlbum = useCallback(async () => {
    try {
      if (album.status?.ordered) {
        throw new Error('Albumul a fost deja comandat!');
      }

      setShowConfirmationModal(false);

      if (!isAlbumConfigured(album)) {
        throw new Error(t('layout.navbar.messages.albumNotConfiguredError'));
      }

      if (!checkForUnnamedAlbums(album)) {
        throw new Error(t('layout.navbar.messages.namelessAlbumsError'));
      }

      if (!checkForZeroAlbumsCount(album)) {
        throw new Error(t('layout.navbar.messages.atLeastOneAlbumError'));
      }

      if (!checkForAlbumsWithNoSize(album)) {
        throw new Error(t('layout.navbar.messages.sizelessAlbumsError'));
      }

      const orderDate = new Date().getTime();

      dispatch(updateAlbum({
        status: 'ordered',
        orderedAt: orderDate,
      }));

      const mappedAlbum = mapAlbumToFirestoreFormat({
        ...album,
        status: 'ordered',
        orderedAt: orderDate,
      });

      // @ts-ignore
      const ref = doc(db, 'albums', albumId);

      await updateDoc(ref, {
        ...mappedAlbum,
        lastUpdatedAt: new Date().getTime(),
      });

      await sendAlbumOrderEmail({ albumId });

      toast.success(finalMessage, toastConfiguration);
    } catch (err) {
      console.error({ err });
      toast.error(`${t('layout.navbar.messages.orderError')}: ${typeof err === 'string' ? err : (err as any).message}`, toastConfiguration);
    }
  }, [
    album,
    albumId,
    toastConfiguration,
    isAlbumConfigured,
    checkForUnnamedAlbums,
    checkForZeroAlbumsCount,
    mapAlbumToFirestoreFormat,
  ]);

  const handleToggleConfirmationsModalVisibility = () => {
    setShowConfirmationModal((prevState) => !prevState);
  };

  const wasAlbumOrdered = useMemo(() => album.status === 'ordered', [album.status]);

  return (
    <>
      <div className={styles.navbar}>
        <div className={styles.navbar__left}>
          <h2 className={styles.navbar__left__title}>
            Studio adidume
          </h2>
        </div>
        {!hideOptions && (
          <div className={styles.navbar__right}>
            {!wasAlbumOrdered && (
              <Button
                type="button"
                mode="alternative"
                handleClick={handlePublishProgressToFirestore}
              >
                {t('layout.navbar.buttons.save')}
              </Button>
            )}
            <CopyToClipboard
              text={window.location.href}
              onCopy={() => {
                toast.success(t('layout.navbar.messages.copyToClipboard'), toastConfiguration);
              }}
            >
              <span
                style={{
                  marginLeft: '0.75rem',
                }}
                dangerouslySetInnerHTML={{
                  __html: ReactDOMServer.renderToString(
                    <Button
                      type="button"
                      mode="alternative"
                    >
                      {t('layout.navbar.buttons.share')}
                    </Button>,
                  ),
                }}
              />
            </CopyToClipboard>
            {!wasAlbumOrdered && (
              <Button
                type="button"
                mode="alternative"
                handleClick={handleToggleConfirmationsModalVisibility}
              >
                {t('layout.navbar.buttons.order')}
              </Button>
            )}
          </div>
        )}
      </div>
      <Suspense>
        <ConfirmationModal
          showModal={showConfirmationModal}
          handleAction={handleOrderAlbum}
          promptText={t('layout.navbar.messages.orderConfirmationModalText')}
          handleToggleModalVisibility={handleToggleConfirmationsModalVisibility}
        />
      </Suspense>
    </>
  );
};

NewNavbar.defaultProps = {
  hideOptions: PropTypes.bool,
};

export default NewNavbar;
