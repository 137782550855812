import styles from './index.module.scss';

import { useSelector } from 'react-redux';
import { UploadedImage } from './components';

import type { TAppState } from '../../../../../store';

const Gallery = () => {
  const { uploadedImages } = useSelector((state: TAppState) => state.uploadedImages);

  return (
    <div className={styles.gallery}>
      {uploadedImages.map(({ id, url, firebaseUrl }) => (
        <UploadedImage
          key={id}
          id={id}
          alt="Image Not found"
          url={url}
          firebaseUrl={firebaseUrl}
        />
      ))}
    </div>
  );
};

export default Gallery;
