import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';

import type { TAppState } from 'src/store';
import type { AlbumCover, AlbumSection } from 'src/types';

const AlbumInformation = () => {
  const { sections, covers, albumConfiguration } = useSelector((state: TAppState) => state.album);

  const { t } = useTranslation();

  const numConfiguredOpenings = useMemo(() => (
    sections?.filter((section: AlbumSection) => section.isConfigured).length
  ), [sections]);

  const numConfiguredCovers = useMemo(() => (
    covers?.filter((cover: AlbumCover) => cover.isConfigured).length
  ), [covers]);

  const minNumberOpenings = useMemo(() => (
    albumConfiguration?.minNumberOpenings
  ), [albumConfiguration]);

  const maxNumberOpenings = useMemo(() => (
    albumConfiguration?.maxNumberOpenings
  ), [albumConfiguration]);

  const minNumberAlbums = useMemo(() => (
    albumConfiguration?.minNumberAlbums
  ), [albumConfiguration]);

  return (
    <div className={styles['album-information-container']}>
      <h4>{t('layout.overviewSidebar.albumInformation.header')}</h4>
      <p>
        {t('layout.overviewSidebar.albumInformation.numNumberOpenings')}
        {' '}
        =
        {' '}
        {minNumberOpenings}
      </p>
      <p>
        {t('layout.overviewSidebar.albumInformation.maxNumberOpenings')}
        {' '}
        =
        {' '}
        {maxNumberOpenings}
      </p>
      <p className={styles.golden}>
        {t('layout.overviewSidebar.albumInformation.numConfiguredOpenings')}
        {' '}
        =
        {' '}
        {numConfiguredOpenings}
      </p>
      <p>
        {t('layout.overviewSidebar.albumInformation.minNumberAlbums')}
        {' '}
        =
        {' '}
        {minNumberAlbums}
      </p>
      <p className={styles.golden}>
        {t('layout.overviewSidebar.albumInformation.numConfiguredCovers')}
        {' '}
        =
        {' '}
        {numConfiguredCovers}
      </p>
    </div>
  );
};

export default AlbumInformation;
