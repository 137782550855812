import {
  FormEvent, useCallback, useEffect, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, TextInput } from '../../components';
import { v4 as uuidv4 } from 'uuid';
import Select from 'react-dropdown-select';
import styles from './Home.module.scss';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import BeatLoader from 'react-spinners/BeatLoader';

import { addAlbum } from 'src/store/album/actions';
import { useTDispatch } from 'src/store';
import { mapAlbumToFirestoreFormat } from 'src/utils/mapAlbumToFirestoreFormat';
import { coverTypes } from 'src/config';
import { doc, setDoc } from 'firebase/firestore';

import type { ContactInformation } from 'src/store/album/types';
import { db } from 'src/firebase';
import { useRetrieveLinkCodeConfiguration } from 'src/hooks/useRetrieveLinkCodeConfiguration';

type Option = {
  label: string;
  value: any;
};

const Home = () => {
  const [name, setName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [deliveryAddress, setDeliveryAddress] = useState('');
  const [albumDesign, setAlbumDesign] = useState<Option[]>([]);

  const [albumDesignOptions, setAlbumDesignOptions] = useState<any[]>([]);

  const { t } = useTranslation();

  const {
    loading,
    albumConfiguration,
    albumDesign: retrievedAlbumDesign,
  } = useRetrieveLinkCodeConfiguration();

  useEffect(() => {
    if (!albumConfiguration || !!retrievedAlbumDesign) return;

    const availableDesigns = albumConfiguration?.availableDesigns || [];

    const mappedAvailableDesigns = availableDesigns.map(
      ({ myId, designName, aspectRatio }: any) => ({
        label: designName,
        value: {
          id: myId,
          aspectRatio,
        },
      }),
    );

    setAlbumDesignOptions(mappedAvailableDesigns);
  }, [albumConfiguration]);

  const dispatch = useTDispatch();

  const handleAlbumDesignChange = (v: unknown) => {
    if (v && Array.isArray(v)) {
      setAlbumDesign(v);
    }
  };

  const navigate = useNavigate();

  const handleFormSubmission = useCallback(async (e: FormEvent) => {
    e.preventDefault();

    try {
      const albumId = uuidv4();

      const contactInformation: ContactInformation = {
        name,
        phoneNumber,
        shippingAddress: deliveryAddress,
      };

      if (!albumConfiguration) return;

      const numberOfEmptyCoverToCreate = albumConfiguration.minNumberAlbums;
      const numberOfEmptySectionsToCreate = albumConfiguration.minNumberOpenings;

      const emptyCovers = [...Array(numberOfEmptyCoverToCreate).keys()].map((k) => ({
        id: uuidv4(),
        empty: true,
        name: '',
        albumSize: '',
        coverType: coverTypes[0],
        discountCode: '',
        price: 0,
        order: k,
        numberOfAlbumsToOrder: 1,
        isConfigured: false,
      }));

      const emptySections = [...Array(numberOfEmptySectionsToCreate).keys()].map((k) => ({
        id: uuidv4(),
        order: k,
        empty: true,
        isConfigured: false,
      }));

      const initialAlbum = {
        id: albumId,
        sections: emptySections,
        covers: emptyCovers,
        albumConfiguration,
        albumDesign: retrievedAlbumDesign ? {
          name: retrievedAlbumDesign.designName,
          id: retrievedAlbumDesign.myId,
          aspectRatio: retrievedAlbumDesign.aspectRatio,
        } : {
          name: albumDesign[0]?.label,
          id: albumDesign[0].value.id,
          aspectRatio: albumDesign[0].value.aspectRatio,
        },
        contactInformation,
      };

      // @ts-ignore
      const ref = doc(db, 'albums', albumId);

      await setDoc(ref, {
        ...mapAlbumToFirestoreFormat(initialAlbum as any),
        lastUpdatedAt: new Date().getTime(),
      });

      dispatch(addAlbum(initialAlbum as any));

      navigate(`${albumId}/edit-content`);
    } catch (err) {
      console.error(err);
    }
  }, [
    albumConfiguration,
    albumDesign,
    name,
    phoneNumber,
    deliveryAddress,
  ]);

  return (
    <div className={styles.layout}>
      {loading ? (
        <div className={styles.overlay}>
          <div className={styles['loader-container']}>
            <BeatLoader
              color="#333"
              loading
              size={15}
              aria-label="Loading Spinner"
            />
          </div>
        </div>
      ) : (
        <div className={styles['content-container']}>
          <div className={styles['entry-content']}>
            <h2>{t('home.welcomeHeader')}</h2>
            <p>{t('home.welcomeParagraph')}</p>
          </div>
          <div className={styles['album-creation-form-wrapper']}>
            <div className={styles['album-creation-form-wrapper__header']}>
              <h2 className={styles['album-creation-form-wrapper__header__title']}>{t('home.form.header')}</h2>
              <p className={styles['album-creation-form-wrapper__header__brand']}>by Studio adidume</p>
            </div>
            <form
              className={styles['album-creation-form-wrapper__album-creation-form']}
              onSubmit={async (e) => {
                await handleFormSubmission(e);
              }}
            >
              <div className={styles['field-wrapper']}>
                <TextInput
                  type="text"
                  name="name"
                  placeholder={t('home.form.name')}
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className={styles['field-wrapper']}>
                <TextInput
                  type="phone"
                  name="phone-number"
                  placeholder={t('home.form.phone')}
                  value={phoneNumber}
                  required
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </div>
              <div className={styles['field-wrapper']}>
                <TextInput
                  type="text"
                  name="delivery-address"
                  placeholder={t('home.form.shippingAddress')}
                  value={deliveryAddress}
                  required
                  onChange={(e) => setDeliveryAddress(e.target.value)}
                />
              </div>
              {!retrievedAlbumDesign && (
                <div className={styles['field-wrapper']}>
                  <Select
                    className={cn([styles['album-type-select'], {
                      [styles['no-value']]: albumDesign.length === 0,
                    }])}
                    values={albumDesign}
                    placeholder={t('home.form.albumDesign')}
                    options={albumDesignOptions}
                    color="#b98d58"
                    dropdownHandle={false}
                    searchable={false}
                    required
                    labelField="label"
                    onChange={handleAlbumDesignChange}
                  />
                </div>
              )}
              <Button
                type="submit"
                filled
                size="md"
              >
                {t('home.form.actionButton')}
              </Button>
            </form>
          </div>
          <p className={styles['website-link']}>www.studio-adidume.ro</p>
        </div>
      )}
    </div>
  );
};

export default Home;
