import { getDownloadURL, ref } from 'firebase/storage';
import { useCallback, useEffect, useState } from 'react';
import { storage } from '../firebase';

import type { StorageError } from 'firebase/storage';

type ErrorCallback = (errorObject: StorageError) => void;

type ReturnType = [boolean, string];

const useGetFirebaseImageUrl = (fileName: string, errorCallback?: ErrorCallback): ReturnType => {
  const [firebaseImageUrl, setFirebaseImageUrl] = useState('');

  const fetchFirebaseImageUrl = useCallback(async () => {
    try {
      const firebaseImageRef = ref(storage, fileName);

      const firebaseImageDownloadUrl = await getDownloadURL(firebaseImageRef);

      setFirebaseImageUrl(firebaseImageDownloadUrl);
    } catch (err: unknown) {
      if (typeof errorCallback === 'function') {
        errorCallback(err as StorageError);
      }
    }
  }, [errorCallback, fileName]);

  useEffect(() => {
    if (!firebaseImageUrl) {
      fetchFirebaseImageUrl();
    }
  }, [fetchFirebaseImageUrl, firebaseImageUrl]);

  return [!firebaseImageUrl, firebaseImageUrl];
};

export default useGetFirebaseImageUrl;
