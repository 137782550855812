import cn from 'classnames';
import styles from './index.module.scss';
import { ReactSVG } from 'react-svg';
import EditIcon from 'src/public/icons/edit.svg';

interface Props {
  className?: string;
  handleClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const FloatingEditButton = ({ className, handleClick }: Props) => (
  <button
    type="button"
    className={cn([className, styles['floating-edit-button']])}
    onClick={handleClick}
  >
    <ReactSVG src={EditIcon} />
  </button>
);

FloatingEditButton.defaultProps = {
  className: '',
};

export default FloatingEditButton;
