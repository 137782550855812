import {
  lazy,
  Suspense,
  useMemo,
  useCallback,
} from 'react';
import styles from './index.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { addNewEmptyAlbumCover, addNewEmptySection } from '../../../../store/album/actions';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';

import type { AlbumCover, AlbumSection } from 'src/types';
import { TAppState } from 'src/store';

const AlbumCoverTemplate = lazy(() => import('./components/AlbumCoverTemplate'));
const TemplateWithText = lazy(() => import('./components/TemplateWithText'));

interface Props {
  selectedView: string;
}

const ContentEditingArea = ({ selectedView }: Props) => {
  const {
    album: {
      sections: albumSections,
      covers: albumCovers,
      albumConfiguration: {
        contentIntroductionMessage = '',
        coverIntroductionMessage = '',
        minNumberAlbums = 0,
        minNumberOpenings = 0,
        maxNumberOpenings = 0,
      } = {
        contentIntroductionMessage: '',
        coverIntroductionMessage: '',
        minNumberAlbums: 0,
        minNumberOpenings: 0,
        maxNumberOpenings: 0,
      },
    },
  } = useSelector((state: TAppState) => state);

  const { t } = useTranslation();

  const covers: AlbumCover[] = useMemo(() => ([...albumCovers]), [albumCovers]);

  const sections: AlbumSection[] = useMemo(() => ([...albumSections]), [albumSections]);

  const canRemoveSection = useMemo(() => (
    sections.length > minNumberOpenings
  ), [sections, minNumberOpenings]);

  const canRemoveCover = useMemo(() => (
    covers.length > minNumberAlbums
  ), [covers, minNumberAlbums]);

  const canAddSection = useMemo(() => (
    sections.length < maxNumberOpenings
  ), [sections, maxNumberOpenings]);

  const isDiscountCodeActive = useMemo(() => (
    covers.some((cover: AlbumCover) => 'hasDiscountCode' in cover)
  ), [covers]);

  const dispatch = useDispatch();

  const contentEditingAreaId = uuidv4();

  const addSection = useCallback(() => {
    if (selectedView === 'album-covers') {
      dispatch(addNewEmptyAlbumCover());
      return;
    }

    if (selectedView !== 'album-covers' && canAddSection) {
      dispatch(addNewEmptySection());
    }
  }, [selectedView, canAddSection]);

  return (
    <div
      className={styles['content-editing-area']}
      data-element-id={contentEditingAreaId}
    >
      <p className={styles['initial-message']}>
        {selectedView === 'album-covers'
          ? coverIntroductionMessage
          : contentIntroductionMessage}
      </p>
      {selectedView === 'album-covers' ? (
        <div className={styles['album-covers-sections-container']}>
          {covers.sort(
            ((a, b) => a.order - b.order),
          ).map(({
            id,
            name,
            albumSize,
            coverType,
            price = 0,
            template = '',
            regions,
            code,
            numberOfAlbumsToOrder,
            hasDiscountCode,
          }) => (
            <Suspense key={id} fallback={<p>{t('loadingText')}</p>}>
              <AlbumCoverTemplate
                contentEditingAreaId={contentEditingAreaId}
                coverId={id}
                name={name}
                image={template}
                albumSize={albumSize}
                code={code}
                coverType={coverType}
                regions={regions}
                numberOfAlbumsToOrder={numberOfAlbumsToOrder}
                price={Number(price)}
                isDiscountCodeActive={isDiscountCodeActive}
                hasDiscountCode={!!hasDiscountCode}
                canRemoveCover={canRemoveCover}
              />
            </Suspense>
          ))}
        </div>
      ) : (
        <div className={styles['album-content-sections-container']}>
          {sections.sort(
            ((a, b) => a.order - b.order),
          ).map(({
            id,
            template = '',
            regions = [],
            order,
            empty: isEmpty,
          }, index: number) => (
            <Suspense key={id} fallback={<p>Se Incarca</p>}>
              <TemplateWithText
                key={id}
                text={`Paginile ${(index + 1) * 2 - 1} - ${(index + 1) * 2}`}
                id={id}
                templateType={
                  order === 0
                    ? 'first-page'
                    : 'content'
                }
                image={template}
                sectionImages={regions}
                contentEditingAreaId={contentEditingAreaId}
                isEmpty={isEmpty}
                canRemoveSection={canRemoveSection}
              />
            </Suspense>
          ))}
        </div>
      )}
      {selectedView !== 'album-covers' && !canAddSection ? null : (
        <button
          className={styles['add-section-btn']}
          type="button"
          onClick={addSection}
        >
          {selectedView === 'album-covers'
            ? t('actions.addNewCover')
            : t('actions.addNewSection')}
        </button>
      )}
    </div>
  );
};

export default ContentEditingArea;
