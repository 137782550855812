import { UploadedImagesActions } from './constants';
import { v4 as uuidv4 } from 'uuid';

export const uploadImage = (sourceUrl: string, firebaseUrl: string) => ({
  type: UploadedImagesActions.UPLOAD_IMAGE,
  payload: {
    id: uuidv4(),
    url: sourceUrl,
    firebaseUrl,
  },
});

export const removeImage = (id: string) => ({
  type: UploadedImagesActions.REMOVE_IMAGE,
  payload: {
    id,
  },
});
