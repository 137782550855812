import { combineReducers, configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';

import { optionReducer } from './option/reducers';
import { uploadedImagesReducer } from './uploadedImages/reducers';
import { focusedSectionReducer } from './focusedSection/reducers';
import { focusedSectionUserImageReducer } from './focusedSectionUserImage/reducers';

import { firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import 'firebase/compat/firestore';
import { firebaseConfig } from '../firebase/config';
import { albumReducer } from './album/reducers';
import { selectedViewReducer } from './selectedView/reducers';

import type { AnyAction } from 'redux';
import type { ThunkDispatch } from 'redux-thunk';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

const app = firebase.initializeApp(firebaseConfig);

app.firestore();

const rootReducer = combineReducers({
  option: optionReducer,
  album: albumReducer,
  uploadedImages: uploadedImagesReducer,
  focusedSection: focusedSectionReducer,
  focusedSectionUserImage: focusedSectionUserImageReducer,
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  selectedView: selectedViewReducer,
});

const store = configureStore({
  reducer: rootReducer,
  devTools: true,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    immutableCheck: false,
    serializableCheck: false,
  }).concat(thunk),
});

export type TAppState = ReturnType<typeof store.getState>;
export type TDispatch = ThunkDispatch<TAppState, void, AnyAction>;

export const useTSelector: TypedUseSelectorHook<typeof store.getState> = useSelector;
export const useTDispatch: () => TDispatch = useDispatch;

export default store;
