import cn from 'classnames';
import styles from './index.module.scss';
import { ReactSVG } from 'react-svg';
import RemoveIcon from 'src/public/icons/remove.svg';

interface Props {
  className?: string;
  handleClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const FloatingRemoveButton = ({ className, handleClick }: Props) => (
  <button
    type="button"
    className={cn([className, styles['floating-remove-button']])}
    onClick={handleClick}
  >
    <ReactSVG src={RemoveIcon} />
  </button>
);

FloatingRemoveButton.defaultProps = {
  className: '',
};

export default FloatingRemoveButton;
