import type { TranslationStructure } from 'src/types';

export const romanianTranslations: TranslationStructure = {
  home: {
    welcomeHeader: 'Bun Venit!',
    welcomeParagraph: 'Studio adidume este locul în care pasiunea se îmbină cu excelența și dă naștere unor albume pe care le vei privi cu drag peste ani.',
    form: {
      header: 'Albume Foto',
      name: 'Nume',
      phone: 'Telefon',
      shippingAddress: 'Adresa De Livrare',
      albumType: 'Tip Album',
      albumDesign: 'Design Album',
      actionButton: 'Configureaza',
    },
  },
  layout: {
    navbar: {
      buttons: {
        share: 'Distribuie',
        order: 'Comanda',
        save: 'Salveaza',
      },
      messages: {
        albumNotConfiguredError: 'Asigura-te ca toate copertele si filele existente au fost configurate!',
        namelessAlbumsError: 'Asigura-te ca toate albumele sunt atribuite unei persoane!',
        atLeastOneAlbumError: 'Asigura-te ca nu exista instante unde numarul de albume comandate este egal cu `0`!',
        sizelessAlbumsError: 'Asigura-te ca toate albumele au o dimensiune atribuita!',
        orderError: 'A aparut o eroare la plasarea comenzii',
        orderConfirmationModalText: 'Esti sigur ca doresti sa comanzi albumul acum?',
        copyToClipboard: 'Link-ul a fost copiat in Clipboard.',
      },
    },
    controlsSidebar: {
      galleryHeader: 'Galerie',
      actions: {
        addPhotos: 'Adauga Fotografii',
      },
      loadingText: 'Se Incarca',
      switch: {
        covers: 'Coperte Album',
        interior: 'Interior Album',
      },
    },
    overviewSidebar: {
      albumInformation: {
        header: 'Informatii Albume',
        numNumberOpenings: 'Nr. minim file/album',
        maxNumberOpenings: 'Nr. maxim file/album',
        numConfiguredOpenings: 'Nr. file configurate',
        minNumberAlbums: 'Nr. minim albume comandate',
        numConfiguredCovers: 'Nr. coperte configurate',
      },
      panelViews: {
        total: 'Pret Total',
        templates: 'Template-uri',
      },
    },
  },
  titleCaseCoverText: 'Coperta',
  titleCaseName: 'Nume',
  titleCaseSectionPagesCountText: 'Paginile',
  loadingText: 'Se Incarca...',
  content: {
    coverToolbarInputs: {
      name: 'Nume',
      albumSize: 'Marime Album',
      coverType: 'Tip Coperta',
      discountCode: 'Cod Discount',
    },
    leatherCoverContent: {
      sizeInput: 'Dimensiune',
      ecoLeatherColor: 'Culoare Piele Eco',
      photoInsert: {
        actions: {
          coverImage: 'Fotografie Coperta',
        },
      },
    },
  },
  confirmationMessages: {
    coverRemoval: 'Esti sigur ca vrei sa stergi aceasta coperta?',
    sectionRemoval: 'Esti sigur ca vrei sa stergi aceasta file?',
  },
  actions: {
    addCoverTemplate: 'Alege Template Pentru Coperta',
    addSectionTemplate: 'Alege Un Template',
    addNewCover: 'Adauga o Coperta Noua',
    addNewSection: 'Adauga o Fila Noua',
  },
  modals: {
    chooseSectionTemplate: 'Alege Template Deschidere',
    chooseLeatherColor: 'Alege Culoarea Preferata',
    chooseCoverTemplate: 'Alege Template Coperta',
    confirmationAffirmative: 'Yes',
    confirmationNegative: 'No',
  },
};
