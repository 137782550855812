import type { AlbumType, FirestoreAlbumType } from '../types';
import { mapAlbumConfigurationToFirestore } from './mapAlbumConfigurationToFirestore';
import { removeUndefinedObjectValues } from './removeUndefinedObjectValues';

export const mapAlbumToFirestoreFormat = (album: AlbumType): FirestoreAlbumType => {
  const sectionsObject = {};
  const coversObject = {};

  album.sections.forEach((section) => {
    const regionsObject = {};

    section?.regions?.forEach((image) => {
      // eslint-disable-next-line no-param-reassign
      removeUndefinedObjectValues(image);

      (regionsObject as any)[image.id] = { ...image };
    });

    (sectionsObject as any)[section.id] = {
      ...section,
      regions: regionsObject,
    };
  });

  album.covers.forEach((cover) => {
    const regionsObject = {};

    cover?.regions?.forEach((image) => {
      // eslint-disable-next-line no-param-reassign
      removeUndefinedObjectValues(image);

      (regionsObject as any)[image.id] = { ...image };
    });

    (coversObject as any)[cover.id] = {
      ...cover,
      regions: regionsObject,
    };
  });

  const mappedAlbum = {
    ...album,
    sections: sectionsObject,
    covers: coversObject,
    albumConfiguration: mapAlbumConfigurationToFirestore(album.albumConfiguration),
  };

  return mappedAlbum;
};
