import { AlbumActions } from './constants';
import { SectionRegions } from './types';
import { v4 as uuidv4 } from 'uuid';
import { coverTypes } from 'src/config';

import type {
  AlbumConfigurationDiscountCode,
  AlbumType,
  PhotoInsertImageConfiguration,
  PhotoInsertSize,
} from '../../types';

export const addAlbum = (albumData: Partial<AlbumType>) => ({
  type: AlbumActions.CREATE_ALBUM,
  payload: albumData,
});

export const updateAlbum = (albumData: any) => ({
  type: AlbumActions.UPDATE_ALBUM,
  payload: albumData,
});

export const removeAlbum = () => ({
  type: AlbumActions.REMOVE_ALBUM,
});

export const addNewEmptySection = () => ({
  type: AlbumActions.ADD_NEW_EMPTY_SECTION,
  payload: {
    id: uuidv4(),
    empty: true,
    isConfigured: false,
  },
});

export const addNewSection = (sectionRegions: SectionRegions) => ({
  type: AlbumActions.ADD_NEW_SECTION,
  payload: {
    id: uuidv4(),
    template: null,
    regions: sectionRegions,
  },
});

export const removeSection = (id: string) => ({
  type: AlbumActions.REMOVE_SECTION,
  payload: {
    id,
  },
});

export const updateSection = ({
  id,
  data,
}: any) => ({
  type: AlbumActions.UPDATE_SECTION,
  payload: {
    id,
    template: data.template,
    regions: data.regions,
    empty: false,
  },
});

export const updateSectionImage = ({
  sectionId,
  imageId,
  imageUrl,
  z,
  firebaseUrl,
  imageSettings,
}: {
  sectionId: string;
  imageId: string;
  imageUrl?: string;
  firebaseUrl?: string;
  z?: number;
  imageSettings?: {
    imageRotationDeg?: number;
    xPixelsDiff?: number;
    yPixelsDiff?: number;
    width?: number;
    height?: number;
    transformation?: string;
    targetWidth?: number;
    targetHeight?: number;
    targetRotation?: number;
    xScale?: number;
    yScale?: number;
    top?: number;
    left?: number;
    initialDragDone?: boolean;
    initialScaleDone?: boolean;
    initialRotateDone?: boolean;
  };
}) => ({
  type: AlbumActions.UPDATE_SECTION_IMAGE,
  payload: {
    sectionId,
    imageId,
    imageUrl,
    firebaseUrl,
    z,
    imageSettings,
  },
});

export const addAlbumCover = (albumCoverData: any) => ({
  type: AlbumActions.UPDATE_ALBUM_COVER,
  payload: albumCoverData,
});

export const addNewEmptyAlbumCover = () => ({
  type: AlbumActions.ADD_NEW_EMPTY_ALBUM_COVER,
  payload: {
    id: uuidv4(),
    empty: true,
    name: '',
    albumSize: '',
    coverType: coverTypes[0],
    discountCode: '',
    price: 0,
    numberOfAlbumsToOrder: 1,
    isConfigured: false,
  },
});

export const updateAlbumCover = (albumCoverData: any) => ({
  type: AlbumActions.UPDATE_ALBUM_COVER,
  payload: albumCoverData,
});

export const updateAlbumCoverImage = ({
  id,
  data,
}: {
  id: string;
  data: any;
}) => ({
  type: AlbumActions.UPDATE_ALBUM_COVER_IMAGE,
  payload: {
    id,
    data: {
      ...data,
      empty: false,
    },
  },
});

export const updateCover = ({
  id,
  data,
}: { id: string; data: any; }) => ({
  type: AlbumActions.UPDATE_COVER,
  payload: {
    id,
    data: {
      ...data,
      ...(data?.template && { empty: false }),
    },
  },
});

export const removeCover = (id: string) => ({
  type: AlbumActions.REMOVE_COVER,
  payload: id,
});

export const setPhotoInsertSize = (
  coverId: string,
  photoInsertSize: PhotoInsertSize,
) => ({
  type: AlbumActions.SET_INSERT_SIZE,
  payload: {
    coverId,
    photoInsertSize,
  },
});

export const setInsertImage = (
  coverId: string,
  imageUrl: string,
  firebaseUrl: string,
) => ({
  type: AlbumActions.SET_INSERT_IMAGE,
  payload: {
    coverId,
    imageUrl,
    firebaseUrl,
  },
});

export const updateInsertPhotoConfiguration = (
  coverId: string,
  insertPhotoConfiguration: PhotoInsertImageConfiguration,
) => ({
  type: AlbumActions.UPDATE_INSERT_PHOTO_CONFIGURATION,
  payload: {
    coverId,
    insertPhotoConfiguration,
  },
});

export const updateSectionTextRegionContent = ({
  sectionId,
  regionId,
  content,
}: {
  sectionId: string;
  regionId: string;
  content: string;
}) => ({
  type: AlbumActions.UPDATE_SECTION_TEXT_REGION_CONTENT,
  payload: {
    sectionId,
    regionId,
    content,
  },
});

export const updateCoverTextRegionContent = ({
  coverId,
  regionId,
  content,
}: {
  coverId: string;
  regionId: string;
  content: string;
}) => ({
  type: AlbumActions.UPDATE_COVER_TEXT_REGION_CONTENT,
  payload: {
    coverId,
    regionId,
    content,
  },
});

export const attachDiscountCode = ({
  coverId,
  discountCode,
}: {
  coverId: string;
  discountCode: AlbumConfigurationDiscountCode;
}) => ({
  type: AlbumActions.ATTACH_DISCOUNT_CODE,
  payload: {
    coverId,
    discountCode,
  },
});

export const removeDiscountCode = (coverId: string) => ({
  type: AlbumActions.REMOVE_DISCOUNT_CODE,
  payload: {
    coverId,
  },
});
