import type {
  AlbumConfiguration,
  AlbumConfigurationAvailableDesign,
  AlbumConfigurationAvailableSize,
  AlbumConfigurationDiscountCode,
  FirestoreAlbumConfiguration,
} from 'src/types';

// eslint-disable-next-line max-len
export const mapAlbumConfigurationFromFirestore = (albumConfiguration: FirestoreAlbumConfiguration): AlbumConfiguration => {
  const obj: Partial<Pick<FirestoreAlbumConfiguration, 'availableSizes' | 'availableDesigns' | 'discountCodes'>>
  & Omit<FirestoreAlbumConfiguration, 'availableSizes' | 'availableDesigns' | 'discountCodes'> = { ...albumConfiguration };

  delete obj.discountCodes;
  delete obj.availableSizes;
  delete obj.availableDesigns;

  const availableSizes: AlbumConfigurationAvailableSize[] = [];
  const discountCodes: AlbumConfigurationDiscountCode[] = [];
  const availableDesigns: AlbumConfigurationAvailableDesign[] = [];

  if ('discountCodes' in albumConfiguration) {
    Object.entries(albumConfiguration.discountCodes).forEach(([key, value]) => {
      discountCodes.push({
        myId: key,
        ...value,
      });
    });
  }

  if ('availableSizes' in albumConfiguration) {
    Object.entries(albumConfiguration.availableSizes).forEach(([key, value]) => {
      availableSizes.push({
        myId: key,
        ...value,
      });
    });
  }

  if ('availableDesigns' in albumConfiguration) {
    Object.entries(albumConfiguration?.availableDesigns)?.forEach(([key, value]) => {
      availableDesigns.push({
        myId: key,
        ...value,
      });
    });
  }

  return {
    ...obj,
    availableSizes,
    availableDesigns,
    discountCodes,
  };
};
