import firebase from 'firebase/compat/app';
import { getFirestore } from 'firebase/firestore';
import 'firebase/compat/database';
import 'firebase/compat/firestore';
import 'firebase/compat/app-check';
import { firebaseConfig } from './config';
import { collections } from './collections';
import { getStorage } from 'firebase/storage';
import { getFunctions } from 'firebase/functions';
import { ReCaptchaV3Provider } from 'firebase/app-check';

const app = firebase.initializeApp(firebaseConfig);

if (window.location.hostname === 'localhost') {
  // eslint-disable-next-line no-restricted-globals
  (self as any).FIREBASE_APPCHECK_DEBUG_TOKEN = true;
}

const appCheck = firebase.appCheck(app);

(() => {
  try {
    appCheck.activate(
      new ReCaptchaV3Provider(process.env.REACT_APP_SITEKEY || ''),
      true,
    );
  } catch (err) {
    console.error('There was an error activating the App Check', err);
  }
})();

export { collections, appCheck };

export const db = getFirestore(app);

export const storage = getStorage(app);

export const functions = getFunctions(app);

export default app;
