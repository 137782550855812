import { useDrag } from 'react-dnd';
import styles from './index.module.scss';
import { useCallback, useRef } from 'react';
import { FloatingRemoveButton } from 'src/components';
import { useDispatch } from 'react-redux';
import { removeImage } from 'src/store/uploadedImages/actions';

const UploadedImage = ({
  id,
  url,
  alt,
  firebaseUrl,
}: {
  id: string;
  url: string;
  alt: string;
  firebaseUrl: string;
}) => {
  const dispatch = useDispatch();

  const containerRef = useRef<HTMLDivElement | null>(null);

  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'image',
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
    item: {
      id,
      image: url,
      firebaseUrl,
    },
  }));

  const handleRemoveImage = useCallback(() => {
    dispatch(removeImage(id));
  }, [id]);

  return (
    <div
      ref={containerRef}
      className={styles['uploaded-gallery-image']}
    >
      <img
        src={url}
        alt={alt}
        style={{
          opacity: isDragging ? 0.5 : 1,
        }}
        ref={drag}
      />
      <FloatingRemoveButton
        className={styles['floating-remove-button']}
        handleClick={handleRemoveImage}
      />
    </div>
  );
};

export default UploadedImage;
