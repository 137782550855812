/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unused-prop-types */
import {
  useMemo, memo, useRef, useCallback,
} from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { FONTS } from 'src/config';
import { getVerticalAlignmentAsFlexProperty } from 'src/utils/getVerticalAlignmentAsFlexProperty';
import ContentEditable from 'react-contenteditable';

import type { Dispatch, SetStateAction } from 'react';
import { useWindowSize } from 'src/hooks/useWindowSize';

interface Props {
  id: string;
  sectionId: string;
  x: number | string;
  y: number | string;
  z: number | string;
  width: number | string;
  height: number | string;
  placeholder?: string;
  lineHeight?: number | string;
  textAlign: 'left' | 'center' | 'right' | 'unset';
  verticalAlignment?: 'top' | 'bottom' | 'center';
  fontSize?: string | number;
  relativeFontSize?: number;
  fontWeight: string | number;
  textColor: string;
  rotationDeg?: number;
  font?: keyof FONTS;
  content?: string;
  isCurrentSectionInFocus: boolean;
  setIsChangingTemplateText: Dispatch<SetStateAction<boolean>>;
  handleUpdateContent: ({
    id,
    content,
    sectionId,
  }: {
    id: string;
    content: string;
    sectionId: string
  }) => void;
}

const TemplateUserText = ({
  id,
  sectionId,
  x,
  y,
  z,
  width,
  height,
  placeholder,
  textAlign,
  fontWeight,
  textColor,
  lineHeight,
  verticalAlignment,
  rotationDeg,
  relativeFontSize,
  setIsChangingTemplateText,
  isCurrentSectionInFocus,
  font,
  content,
  handleUpdateContent,
}: Props) => {
  const zIndex = useMemo(() => {
    if (isCurrentSectionInFocus) {
      return Number(z) + 3000;
    }

    return Number(z) + 1;
  }, [z, isCurrentSectionInFocus]);

  const pRef = useRef<HTMLParagraphElement | null>(null);

  const computedStyles = useMemo(
    () => ({
      left: `${x}%`,
      top: `${y}%`,
      width: `${width}%`,
      height: `${height}%`,
      maxHeight: `${height}%`,
      zIndex,
      textAlign,
      fontWeight,
      color: textColor,
      lineHeight,
      transform: `rotate(${rotationDeg}deg)`,
      justifyContent: getVerticalAlignmentAsFlexProperty(verticalAlignment),
    }),
    [
      x,
      y,
      zIndex,
      width,
      height,
      textAlign,
      rotationDeg,
      fontWeight,
      textColor,
      lineHeight,
      verticalAlignment,
    ],
  );

  const { height: windowHeight, width: windowWidth } = useWindowSize();

  const handleFontSize = useCallback((el: HTMLParagraphElement | null) => {
    if (el) {
      const parentWidth = el.parentElement?.offsetWidth;

      if (parentWidth && relativeFontSize) {
        el.style.setProperty('font-size', `${((relativeFontSize * parentWidth) / 100)}px`);
      }
    }
  }, [relativeFontSize, windowHeight, windowWidth]);

  return (
    <div
      className={styles['user-editable-text-region-container']}
      style={computedStyles}
      ref={handleFontSize}
    >
      <ContentEditable
        html={content || placeholder || ''}
        tagName="p"
        innerRef={(el: any) => {
          pRef.current = el;

          if (el) {
            el?.style.setProperty('font-family', Object(FONTS)[font || 'POPPINS'], 'important');
          }
        }}
        className={styles['user-editable-text-region-container__user-editable-text-region']}
        onChange={(e) => {
          handleUpdateContent({
            id,
            sectionId,
            content: e.currentTarget.textContent || content || '',
          });
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            document.execCommand('insertLineBreak');
            e.preventDefault();
          }
        }}
        onFocus={() => {
          setIsChangingTemplateText(true);
        }}
        onBlur={() => {
          setIsChangingTemplateText(false);
        }}
      />
    </div>
  );
};

TemplateUserText.propTypes = {
  x: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  y: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  z: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  placeholder: PropTypes.string,
  rotationDeg: PropTypes.number,
};

TemplateUserText.defaultProps = {
  placeholder: '',
  rotationDeg: 0,
  relativeFontSize: 50,
  font: 'POPPINS',
};

export default memo(TemplateUserText);
