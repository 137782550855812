/* eslint react/forbid-prop-types: 0 */
/* eslint-disable no-nested-ternary */
import { useMemo, memo } from 'react';
import { FirebaseFallbackImage } from 'src/components';
import styles from './index.module.scss';
import PropTypes from 'prop-types';

interface Props {
  left?: number;
  top?: number;
  targetRotation?: number;
  xScale?: number;
  yScale?: number;
  imageUrl: string;
  firebaseImageUrl: string;
  setImageRef: (elm: HTMLImageElement | null) => void;
}

const ComputedImage = ({
  top,
  left,
  targetRotation,
  xScale,
  yScale,
  imageUrl,
  firebaseImageUrl,
  setImageRef,
}: Props) => {
  const hasImage = useMemo(() => (!!imageUrl || !!firebaseImageUrl), [imageUrl, firebaseImageUrl]);

  const imageComputedStyles = useMemo(() => ({
    transform: `rotate(${targetRotation}deg) scale(${xScale}, ${yScale}) translate(${left}%, ${top}%)`,
  }), [
    left,
    top,
    targetRotation,
    xScale,
    yScale,
    imageUrl,
    firebaseImageUrl,
  ]);

  return (
    <div
      className={styles['image-section-wrapper']}
      style={{
        overflow: !hasImage ? 'unset' : 'hidden',
      }}
    >
      <FirebaseFallbackImage
        key={imageUrl + firebaseImageUrl}
        localImageUrl={imageUrl}
        firebaseImageUrl={firebaseImageUrl}
        emitRef={setImageRef}
        stringifiedStyles={JSON.stringify(imageComputedStyles)}
      />
    </div>
  );
};

ComputedImage.propTypes = {
  left: PropTypes.number,
  top: PropTypes.number,
  targetRotation: PropTypes.number,
  xScale: PropTypes.number,
  yScale: PropTypes.number,
  imageUrl: PropTypes.string.isRequired,
  firebaseImageUrl: PropTypes.string.isRequired,
  setImageRef: PropTypes.func.isRequired,
};

ComputedImage.defaultProps = {
  targetRotation: 0,
  top: 0,
  left: 0,
  xScale: 1,
  yScale: 1,
};

export default memo(ComputedImage);
