/* eslint-disable react/require-default-props */

import {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import styles from './index.module.scss';
import useAdjustContainerHeight from 'src/hooks/useAdjustContainerHeight';
import { FirebaseFallbackImage } from 'src/components';
import { getInsertSizeAsPercentage } from './utils/getInsertSizeAsPercentage';
import { useSelector } from 'react-redux';

import type { AvailableCoverSize, PhotoInsertConfiguration } from 'src/types';
import type { TAppState } from 'src/store';

interface Props {
  albumSize: AvailableCoverSize;
  photoInsertConfiguration?: PhotoInsertConfiguration;
  firebaseImageUrl: string;
}

const LeatherCoverSection = ({ photoInsertConfiguration, firebaseImageUrl, albumSize }: Props) => {
  const [imageComputedStyles, setImageComputedStyles] = useState({
    transform: '',
  });

  const { availableSizes } = useSelector((state: TAppState) => state.album.albumConfiguration);

  const imageRef = useRef<HTMLImageElement | null>(null);
  const sectionRef = useRef<HTMLDivElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const {
    left = 0,
    top = 0,
    rotation = 0,
    xScale = 1,
    yScale = 1,
  } = useMemo(() => (
    photoInsertConfiguration?.imageConfiguration || {}
  ), [photoInsertConfiguration?.imageConfiguration]);

  const computedHeight = useAdjustContainerHeight(sectionRef, 60);

  const computedSectionStyles = useMemo(() => ({
    height: `${computedHeight}px`,
  }), [computedHeight]);

  const updateImageComputedStyles = useCallback(() => {
    let computedLeft = left;
    let computedTop = top;

    if (imageRef.current && containerRef.current) {
      const centerX = imageRef.current.offsetLeft + imageRef.current.offsetWidth / 2;
      const centerY = imageRef.current.offsetTop + imageRef.current.offsetHeight / 2;

      const centerX2 = containerRef.current.offsetWidth / 2;
      const centerY2 = containerRef.current.offsetHeight / 2;

      const leftOffset = centerX - centerX2;
      const topOffset = centerY - centerY2;

      const leftOffsetAsPercentage = ((leftOffset / imageRef.current.offsetWidth) * 100);
      const topOffsetAsPercentage = ((topOffset / imageRef.current.offsetHeight) * 100);

      if (computedTop === 0) {
        computedTop = -topOffsetAsPercentage / 2;
      }

      if (computedLeft === 0) {
        computedLeft = -leftOffsetAsPercentage / 2;
      }
    }

    setImageComputedStyles({
      transform: `rotate(${rotation}deg) scale(${xScale}, ${yScale}) translate(${computedLeft}%, ${computedTop}%)`,
    });
  }, [
    left,
    top,
    rotation,
    xScale,
    yScale,
    photoInsertConfiguration,
    firebaseImageUrl,
  ]);

  useEffect(() => {
    updateImageComputedStyles();

    window.addEventListener('resize', updateImageComputedStyles);

    return () => {
      window.removeEventListener('resize', updateImageComputedStyles);
    };
  }, [
    left,
    top,
    rotation,
    xScale,
    yScale,
    photoInsertConfiguration,
  ]);

  const { width, height } = useMemo(() => (
    getInsertSizeAsPercentage(
      albumSize.value,
      availableSizes,
      photoInsertConfiguration,
    )
  ), [albumSize, availableSizes, photoInsertConfiguration]);

  const computedStyles = useMemo(() => ({
    width: `${width}%`,
    height: `${height}%`,
  }), [width, height, photoInsertConfiguration]);

  return (
    <div
      ref={sectionRef}
      style={computedSectionStyles}
      className={styles['minimap-leather-section']}
    >
      <img
        className={styles['section-template-image']}
        src={firebaseImageUrl}
      />
      <div
        className={styles['section-image-wrapper']}
        style={computedStyles}
        ref={containerRef}
      >
        <FirebaseFallbackImage
          // eslint-disable-next-line no-unsafe-optional-chaining
          key={`${photoInsertConfiguration?.imageUrl}_${firebaseImageUrl}`}
          localImageUrl={photoInsertConfiguration?.imageUrl || ''}
          firebaseImageUrl={photoInsertConfiguration?.firebaseUrl || ''}
          stringifiedStyles={JSON.stringify(imageComputedStyles)}
          emitRef={imageRef}
        />
      </div>
    </div>
  );
};

export default LeatherCoverSection;
