import PropTypes from 'prop-types';
import styles from './Layout.module.scss';
import { NewNavbar } from './components';

import type { PropsWithChildren } from 'react';

interface Props {
  hideOptions?: boolean;
}

const Layout = ({ hideOptions = false, children }: PropsWithChildren<Props>) => (
  <div className={styles.layout}>
    <NewNavbar hideOptions={hideOptions} />
    {children}
  </div>
);

Layout.defaultProps = {
  hideOptions: PropTypes.bool,
};

export default Layout;
