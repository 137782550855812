import { useMemo, useRef } from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { SectionImage } from './components';
import useAdjustContainerHeight from 'src/hooks/useAdjustContainerHeight';
import SectionText from './components/SectionText';
import { FONTS } from 'src/config';

interface Props {
  image?: string;
  id: string;
  handleSectionScroll: any;
  sectionImages: Array<{
    id: string;
    x: number | string;
    y: number | string;
    z: number | string;
    width: number | string;
    height: number | string;
    type?: 'photo' | 'text';
    placeholder?: string;
    textAlign?: 'left' | 'center' | 'right';
    font?: keyof FONTS;
    fontSize?: string | number;
    fontWeight?: string | number;
    textColor?: string;
    imageUrl?: string;
    lineHeight?: number | string;
    firebaseUrl?: string;
    rotationDeg?: number;
    relativeFontSize?: number;
    content?: string;
    imageSettings?: {
      width?: number;
      height?: number;
      targetWidth?: number;
      targetHeight?: number;
      targetRotation?: number;
      top?: number;
      left?: number;
      xScale?: number;
      yScale?: number;
    };
  }>;
}

const Section = ({
  id: sectionId,
  image,
  sectionImages,
  handleSectionScroll,
}: Props) => {
  const sectionRef = useRef<HTMLDivElement | null>(null);

  const computedHeight = useAdjustContainerHeight(sectionRef, 60);

  const computedStyles = useMemo(() => ({
    height: `${computedHeight}px`,
  }), [computedHeight]);

  return (
    <div
      className={styles['minimap-section']}
      ref={sectionRef}
      style={computedStyles}
      onClick={() => handleSectionScroll(sectionId)}
    >
      <img className={styles['section-template-image']} src={image} />
      {sectionImages.map(
        ({
          id,
          x,
          y,
          z,
          width,
          height,
          imageUrl,
          rotationDeg,
          firebaseUrl,
          type,
          imageSettings,
          placeholder,
          fontSize,
          fontWeight,
          textAlign,
          textColor,
          relativeFontSize,
          lineHeight,
          font,
          content,
        }) => (
          type === 'photo' ? (
            <SectionImage
              key={imageUrl + id}
              x={x}
              y={y}
              z={z}
              width={width}
              height={height}
              imageUrl={imageUrl}
              targetWidth={imageSettings?.targetWidth}
              targetHeight={imageSettings?.targetHeight}
              targetRotation={imageSettings?.targetRotation}
              top={imageSettings?.top}
              left={imageSettings?.left}
              xScale={imageSettings?.xScale}
              yScale={imageSettings?.yScale}
              firebaseImageUrl={firebaseUrl}
              rotationDeg={rotationDeg}
            />
          ) : (
            <SectionText
              key={id}
              x={x}
              y={y}
              z={z}
              height={height}
              width={width}
              placeholder={placeholder}
              textAlign={textAlign || 'center'}
              fontSize={fontSize || 16}
              fontWeight={fontWeight || 'unset'}
              textColor={textColor || 'unset'}
              lineHeight={lineHeight || 'unset'}
              rotationDeg={rotationDeg}
              font={font}
              relativeFontSize={relativeFontSize}
              content={content}
            />
          )
        ),
      )}
    </div>
  );
};

Section.propTypes = {
  image: PropTypes.string,
  id: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types, react/require-default-props
  handleSectionScroll: PropTypes.any,
  sectionImages: PropTypes.arrayOf(
    PropTypes.shape({
      x: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      y: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      z: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      width: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      imageUrl: PropTypes.string,
    }),
  ).isRequired,
};

Section.defaultProps = {
  image: '',
};

export default Section;
