import { useEffect, useState } from 'react';
import useGetFirebaseImageUrl from '../../hooks/useGetFirebaseImageUrl';

interface Props {
  localImageUrl: string;
  firebaseImageUrl: string;
  emitRef?: ((elm: HTMLImageElement | null) => void)
  | (React.MutableRefObject<HTMLImageElement | null>);
  stringifiedStyles?: string;
  handleImageLoad?: () => void;
}

const FirebaseFallbackImage = ({
  localImageUrl,
  firebaseImageUrl,
  emitRef,
  stringifiedStyles,
  handleImageLoad,
}: Props) => {
  const [imageUrl, setImageUrl] = useState(localImageUrl);
  const [fatalError, setFatalError] = useState(false);

  const [isLoading, fetchedFirebaseImageUrl] = useGetFirebaseImageUrl(firebaseImageUrl);

  useEffect(() => {
    if (!imageUrl && fetchedFirebaseImageUrl) {
      setImageUrl(fetchedFirebaseImageUrl);
    }
  }, [localImageUrl, fetchedFirebaseImageUrl]);

  const parsedImageStyles = stringifiedStyles
    ? JSON.parse(stringifiedStyles)
    : {};

  const handleImageLoadingError = () => {
    if (!fetchedFirebaseImageUrl) {
      setFatalError(true);
    }

    if (fetchedFirebaseImageUrl) {
      setImageUrl(fetchedFirebaseImageUrl);
    }
  };

  if (!imageUrl) {
    return null;
  }

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (fatalError) {
    return (
      // eslint-disable-next-line react/no-unescaped-entities
      <p>Couldn't find image</p>
    );
  }

  return (
    <img
      src={imageUrl}
      ref={emitRef}
      style={parsedImageStyles}
      onError={handleImageLoadingError}
      onLoad={handleImageLoad}
    />
  );
};

FirebaseFallbackImage.defaultProps = {
  emitRef: undefined,
  stringifiedStyles: '',
  handleImageLoad: undefined,
};

export default FirebaseFallbackImage;
