/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unused-prop-types */
import { useMemo, memo } from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { FONTS } from 'src/config';
import { getVerticalAlignmentAsFlexProperty } from 'src/utils/getVerticalAlignmentAsFlexProperty';

interface Props {
  x: number | string;
  y: number | string;
  z: number | string;
  width: number | string;
  height: number | string;
  placeholder?: string;
  lineHeight?: number | string;
  textAlign: 'left' | 'center' | 'right' | 'unset';
  verticalAlignment?: 'top' | 'bottom' | 'center';
  fontSize?: string | number;
  relativeFontSize?: number;
  fontWeight: string | number;
  textColor: string;
  rotationDeg?: number;
  font?: keyof FONTS;
  content?: string;
}

const SectionText = ({
  x,
  y,
  z,
  width,
  height,
  placeholder,
  textAlign,
  lineHeight,
  verticalAlignment,
  fontWeight,
  textColor,
  rotationDeg,
  relativeFontSize,
  font,
  content,
}: Props) => {
  const computedStyles = useMemo(
    () => ({
      left: `${x}%`,
      top: `${y}%`,
      width: `${width}%`,
      height: `${height}%`,
      maxHeight: `${height}%`,
      zIndex: z,
      textAlign,
      fontWeight,
      color: textColor,
      lineHeight,
      transform: `rotate(${rotationDeg}deg)`,
      justifyContent: getVerticalAlignmentAsFlexProperty(verticalAlignment),
    }),
    [
      x,
      y,
      z,
      width,
      height,
      textAlign,
      rotationDeg,
      fontWeight,
      textColor,
      lineHeight,
      verticalAlignment,
    ],
  );

  return (
    <div
      className={styles['user-editable-text-region-container']}
      style={computedStyles}
      ref={(el) => {
        if (el) {
          const parentWidth = el.parentElement?.offsetWidth;

          if (parentWidth && relativeFontSize) {
            el.style.setProperty('font-size', `${((relativeFontSize * parentWidth) / 100)}px`);
          }
        }
      }}
    >
      <p
        ref={(el) => {
          if (el) {
            el.style.setProperty('font-family', Object(FONTS)[font || 'POPPINS'], 'important');
          }
        }}
        className={styles['user-editable-text-region']}
      >
        { content || placeholder }
      </p>
    </div>
  );
};

SectionText.propTypes = {
  x: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  y: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  z: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  placeholder: PropTypes.string,
  rotationDeg: PropTypes.number,
};

SectionText.defaultProps = {
  placeholder: '',
  rotationDeg: 0,
  relativeFontSize: 50,
  font: 'POPPINS',
};

export default memo(SectionText);
