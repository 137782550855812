/* eslint react/forbid-prop-types: 0 */
import Moveable, { OnDrag, OnRotate, OnScale } from 'react-moveable';
import { createPortal, flushSync } from 'react-dom';
import PropTypes from 'prop-types';
import { memo } from 'react';

interface Props {
  targetImage: HTMLImageElement;
  sectionElm: HTMLDivElement;
  handleDrag: (args: OnDrag) => void;
  handleScale: (args: OnScale) => void;
  handleRotate: (args: OnRotate) => void;
}

const ImageControls = ({
  targetImage,
  sectionElm,
  handleDrag,
  handleRotate,
  handleScale,
}: Props) => createPortal(
  <Moveable
    target={targetImage}
    draggable
    scalable
    flushSync={flushSync}
    rotatable
    rotateAroundControls
    edgeDraggable={false}
    origin
    originDraggable
    originRelative
    transformOrigin={[50, 50]}
    dragArea
    keepRatio
    onDrag={handleDrag}
    onScale={handleScale}
    onRotate={handleRotate}
    zoom={1}
    defaultGroupOrigin="50% 50%"
  />,
  sectionElm,
);

ImageControls.propTypes = {
  targetImage: PropTypes.any.isRequired,
  sectionElm: PropTypes.any.isRequired,
  handleDrag: PropTypes.func.isRequired,
  handleScale: PropTypes.func.isRequired,
  handleRotate: PropTypes.func.isRequired,
};

export default memo(ImageControls);
