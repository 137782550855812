import { isCoverConfigured } from 'src/utils/isCoverConfigured';
import { isSectionConfigured } from 'src/utils/isSectionConfigured';
import { AlbumSection, SectionImage, AlbumCover } from '../../types';
import { getAlbumPrice } from '../utils/getAlbumPrice';
import { AlbumActions } from './constants';
import { TAction } from './types';

const initialState: any = {
  id: '',
  sections: [],
  covers: [],
  contactInformation: {
    name: '',
    phoneNumber: '',
    shippingAddress: '',
  },
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export const albumReducer = (state = initialState, action: TAction) => {
  switch (action.type) {
    case AlbumActions.CREATE_ALBUM:
      return {
        ...state,
        ...action.payload,
      };

    case AlbumActions.UPDATE_ALBUM:
      return {
        ...state,
        ...action.payload,
      };

    case AlbumActions.REMOVE_ALBUM:
      return {};

    case AlbumActions.ADD_NEW_EMPTY_SECTION: {
      const newState = {
        ...state,
        sections: [
          ...state.sections,
          {
            ...action.payload,
            order: state.sections.length,
          },
        ],
      };

      return {
        ...newState,
        covers: newState.covers.map((cover: AlbumCover) => ({
          ...cover,
          price: getAlbumPrice(newState, {
            ...cover,
          }),
        })),
      };
    }

    case AlbumActions.SETUP_EMPTY_SECTIONS:
      return {
        ...state,
        sections: action.payload,
      };

    case AlbumActions.ADD_NEW_SECTION: {
      const newState = {
        ...state,
        sections: [
          ...state.sections,
          {
            ...action.payload,
            order: state.sections.length,
          },
        ],
      };

      return {
        ...newState,
        covers: newState.covers.map((cover: AlbumCover) => ({
          ...cover,
          price: getAlbumPrice(newState, {
            ...cover,
          }),
        })),
      };
    }

    case AlbumActions.UPDATE_SECTION:
      return {
        ...state,
        sections: state.sections?.map((section: any) => {
          if (section?.id === action.payload.id) {
            const updatedSection = {
              ...section,
              ...action.payload,
            };

            return {
              ...updatedSection,
              isConfigured: isSectionConfigured(updatedSection),
            };
          }

          return section;
        }),
      };

    case AlbumActions.UPDATE_SECTION_IMAGE:
      return {
        ...state,
        sections: state.sections?.map((section: AlbumSection) => {
          if (section?.id === action.payload.sectionId) {
            const sectionWithUpdatedImage = {
              ...section,
              regions: section.regions.map((sectionImage: SectionImage) => {
                if (sectionImage?.id === action.payload.imageId) {
                  return {
                    ...sectionImage,
                    imageUrl: action.payload.imageUrl || sectionImage?.imageUrl,
                    firebaseUrl: action.payload.firebaseUrl || sectionImage?.firebaseUrl,
                    // eslint-disable-next-line max-len
                    z: action.payload.z || sectionImage?.z,
                    imageSettings: {
                      ...sectionImage.imageSettings,
                      ...action.payload.imageSettings,
                    },
                  };
                }

                return sectionImage;
              }),
            };

            return {
              ...sectionWithUpdatedImage,
              isConfigured: isSectionConfigured(sectionWithUpdatedImage),
            };
          }

          return section;
        }),
      };

    case AlbumActions.REMOVE_SECTION: {
      const sectionToRemove: AlbumSection | undefined = state.sections
        .find((section: AlbumSection) => section.id === action.payload.id);

      if (!sectionToRemove) return state;

      return {
        ...state,
        sections: state.sections
          .filter((section: AlbumSection) => section.id !== action.payload.id)
          .map((section: AlbumSection) => {
            if (section.order > sectionToRemove.order) {
              return {
                ...section,
                order: section.order - 1,
              };
            }

            return section;
          }),
      };
    }

    case AlbumActions.UPDATE_ALBUM_COVER_IMAGE:
      return {
        ...state,
        covers: state.covers?.map((cover: any) => {
          if (cover.id === action.payload.id) {
            const coverWithUpdatedImage = {
              ...cover,
              regions: cover?.regions.map((coverImage: any) => {
                if (coverImage.id === action.payload.data.imageId) {
                  return {
                    ...coverImage,
                    imageUrl: action.payload.data.imageUrl || coverImage?.imageUrl,
                    firebaseUrl: action.payload.data.firebaseUrl || coverImage?.firebaseUrl,
                    // eslint-disable-next-line max-len
                    z: action.payload.data.z || coverImage?.z,
                    imageSettings: {
                      ...coverImage.imageSettings,
                      ...action.payload.data.imageSettings,
                    },
                  };
                }

                return coverImage;
              }) || [],
            };

            return {
              ...coverWithUpdatedImage,
              isConfigured: isCoverConfigured(coverWithUpdatedImage),
            };
          }

          return cover;
        }),
      };

    case AlbumActions.ADD_NEW_EMPTY_ALBUM_COVER:
      return {
        ...state,
        covers: [
          ...state.covers,
          {
            ...action.payload,
            order: state.covers.length,
          },
        ],
      };

    case AlbumActions.SETUP_EMPTY_COVERS:
      return {
        ...state,
        covers: action.payload,
      };

    case AlbumActions.UPDATE_COVER:
      return {
        ...state,
        covers: state.covers.map((cover: any) => {
          if (cover.id === action.payload.id) {
            const updatedCover = {
              ...cover,
              ...action.payload.data,
              price: getAlbumPrice(state, {
                ...cover,
                ...action.payload.data,
              }),
            };

            return {
              ...updatedCover,
              isConfigured: isCoverConfigured(updatedCover),
            };
          }

          return cover;
        }),
      };

    case AlbumActions.REMOVE_COVER: {
      const coverToRemove: AlbumCover | undefined = state.covers
        .find((cover: AlbumCover) => cover.id === action.payload);

      if (!coverToRemove) return state;

      return {
        ...state,
        covers: state.covers
          .filter((cover: AlbumCover) => cover.id !== action.payload)
          .map((cover: AlbumCover) => {
            if (cover.order > coverToRemove.order) {
              return {
                ...cover,
                order: cover.order - 1,
              };
            }

            return cover;
          }),
      };
    }

    case AlbumActions.SET_INSERT_SIZE:
      return {
        ...state,
        covers: state.covers?.map((cover: any) => {
          if (cover.id === action.payload.coverId) {
            const coverWithInsertSize = {
              ...cover,
              photoInsertConfiguration: {
                ...cover?.photoInsertConfiguration,
                size: action.payload.photoInsertSize,
                imageConfiguration: {
                  ...cover?.photoInsertConfiguration?.imageConfiguration,
                  initialScaleDone: false,
                  initialDragDone: false,
                },
              },
            };

            return {
              ...coverWithInsertSize,
              isConfigured: isCoverConfigured(coverWithInsertSize),
              price: getAlbumPrice(state, coverWithInsertSize),
            };
          }

          return cover;
        }),
      };

    case AlbumActions.SET_INSERT_IMAGE:
      return {
        ...state,
        covers: state.covers?.map((cover: any) => {
          if (cover.id === action.payload.coverId) {
            const coverWithInsertImage = {
              ...cover,
              photoInsertConfiguration: {
                ...cover?.photoInsertConfiguration,
                imageUrl: action.payload.imageUrl,
                firebaseUrl: action.payload.firebaseUrl,
                imageConfiguration: {
                  ...cover?.photoInsertConfiguration?.imageConfiguration,
                  xScale: 1,
                  yScale: 1,
                  top: 0,
                  left: 0,
                  rotation: 0,
                  initialScaleDone: false,
                  initialDragDone: false,
                },
              },
            };

            return {
              ...coverWithInsertImage,
              isConfigured: isCoverConfigured(coverWithInsertImage),
              price: getAlbumPrice(state, coverWithInsertImage),
            };
          }

          return cover;
        }),
      };

    case AlbumActions.UPDATE_INSERT_PHOTO_CONFIGURATION:
      return {
        ...state,
        covers: state.covers?.map((cover: any) => {
          if (cover.id === action.payload.coverId) {
            const coverWithUpdatedInsertPhotoConfiguration = {
              ...cover,
              photoInsertConfiguration: {
                ...cover?.photoInsertConfiguration,
                imageConfiguration: {
                  ...cover?.photoInsertConfiguration?.imageConfiguration,
                  ...action.payload.insertPhotoConfiguration,
                },
              },
            };

            return {
              ...coverWithUpdatedInsertPhotoConfiguration,
              isConfigured: isCoverConfigured(coverWithUpdatedInsertPhotoConfiguration),
              price: getAlbumPrice(state, coverWithUpdatedInsertPhotoConfiguration),
            };
          }

          return cover;
        }),
      };

    case AlbumActions.UPDATE_SECTION_TEXT_REGION_CONTENT:
      return {
        ...state,
        sections: state.sections?.map((section: AlbumSection) => {
          if (section?.id === action.payload.sectionId) {
            const sectionWithUpdatedTextRegionContent = {
              ...section,
              regions: section.regions.map((sectionImage: SectionImage) => {
                if (sectionImage?.id === action.payload.regionId) {
                  return {
                    ...sectionImage,
                    content: action.payload.content,
                  };
                }

                return sectionImage;
              }),
            };

            return {
              ...sectionWithUpdatedTextRegionContent,
              isConfigured: isSectionConfigured(sectionWithUpdatedTextRegionContent),
            };
          }

          return section;
        }),
      };

    case AlbumActions.UPDATE_COVER_TEXT_REGION_CONTENT:
      return {
        ...state,
        covers: state.covers?.map((cover: any) => {
          if (cover.id === action.payload.coverId) {
            const coverWithUpdatedTextRegionContent = {
              ...cover,
              regions: cover?.regions.map((coverImage: any) => {
                if (coverImage.id === action.payload.regionId) {
                  return {
                    ...coverImage,
                    content: action.payload.content,
                  };
                }

                return coverImage;
              }) || [],
            };

            return {
              ...coverWithUpdatedTextRegionContent,
              isConfigured: isCoverConfigured(coverWithUpdatedTextRegionContent),
            };
          }

          return cover;
        }),
      };

    case AlbumActions.ATTACH_DISCOUNT_CODE:
      return {
        ...state,
        covers: state.covers?.map((cover: any) => {
          if (cover.id === action.payload.coverId) {
            const coverWithDiscountCode = {
              ...cover,
              hasDiscountCode: true,
              discountCode: action.payload.discountCode,
            };

            return {
              ...coverWithDiscountCode,
              price: getAlbumPrice(state, coverWithDiscountCode),
            };
          }

          return cover;
        }),
      };

    case AlbumActions.REMOVE_DISCOUNT_CODE:
      return {
        ...state,
        covers: state.covers?.map((cover: any) => {
          if (cover.id === action.payload.coverId) {
            const coverCopy = { ...cover };

            if ('hasDiscountCode' in coverCopy) {
              delete coverCopy.hasDiscountCode;
            }

            if ('discountCode' in coverCopy) {
              delete coverCopy.discountCode;
            }

            return {
              ...coverCopy,
              price: getAlbumPrice(state, coverCopy),
            };
          }

          return cover;
        }),
      };

    default:
      return state;
  }
};
