import type { CoverType, PhotoInsertSize } from "src/types";
import type { ToastOptions } from "react-toastify";

export enum OPTIONS {
  PICTURES = 'pictures',
  LAYOUTS = 'layouts',
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum OPTIONS_DESCRIPTION {
  PICTURES = 'Drag an image onto the page in order to add it.',
  LAYOUTS = 'Select a layout in order to apply it',
}

export const coverTypes: CoverType[] = [
  {
    label: 'Hard Cover (Foto)',
    value: 'hard-cover',
  },
  {
    label: 'Piele Eco',
    value: 'leather-cover',
  },
];

export const availablePhotoInsertSizes: PhotoInsertSize[] = [
  {
    value: '6x6',
    label: '6x6 cm',
  },
  {
    value: '6x9',
    label: '6x9 cm',
  },
  {
    value: '9x6',
    label: '9x6 cm',
  },
];

export enum FONTS {
  ABRIL_FATFACE = "'Abril Fatface', cursive",
  CINZEL = "'Cinzel', serif",
  COOKIE = "'Cookie', cursive",
  DM_SERIF_DISPLAY = "'DM Serif Display', serif",
  GELASIO = "'Gelasio', serif",
  GREAT_VIBES = "'Great Vibes', cursive",
  KAUSHAN_SCRIPT = "'Kaushan Script', cursive",
  LATO = "'Lato', sans-serif",
  MONOTON = "'Monoton', cursive",
  MONTSERRAT = "'Montserrat', sans-serif",
  OLEO_SCRIPT = "'Oleo Script', cursive",
  OSWALD = "'Oswald', sans-serif",
  PETIT_FORMAL_SCRIPT = "'Petit Formal Script', cursive",
  PLAYFAIR_DISPLAY = "'Playfair Display', serif",
  ROUGE_SCRIPT = "'Rouge Script', cursive",
  SACRAMENTO = "'Sacramento', cursive",
  SPECTRAL = "'Spectral', serif",
  STYLE_SCRIPT = "'Style Script', cursive",
  TANGERINE = "'Tangerine', cursive",
  YESEVA_ONE = "'Yeseva One', cursive",
  POPPINS = "'Poppins', sans-serif ",
}

export const toastConfiguration: ToastOptions = {
  autoClose: 5000,
  position: 'top-center',
  theme: "light",
  style: {
    width: '600px',
  },
};

export const modalStyles = {
  overlay: {
    zIndex: 1000000000,
  },
  content: {
    width: '60vw',
    height: '50vh',
    minWidth: '600px',
    minHeight: '500px',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
};