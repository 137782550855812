import { AlbumSection } from 'src/types';

export const isSectionConfigured = (section: AlbumSection) => {
  if (!section.template) return false;

  return section.regions.every((region) => {
    if (region.type === 'photo') {
      return !!region.firebaseUrl;
    }

    return 'content' in region && !!region.content;
  });
};
