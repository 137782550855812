import { AlbumCover } from 'src/types';

const isHardCoverConfigured = (cover: AlbumCover): boolean => {
  if (!cover.template) return false;

  return cover.regions.every((region) => {
    if (region.type === 'photo') {
      return !!region.firebaseUrl;
    }

    return 'content' in region && !!region.content;
  });
};

const isLeatherCoverConfigured = (cover: AlbumCover): boolean => {
  if (!cover.photoInsertConfiguration) return false;

  if (cover.photoInsertConfiguration.size.value === 'no-insert') return false;

  return !!(cover.photoInsertConfiguration?.firebaseUrl || cover.code);
};

export const isCoverConfigured = (cover: AlbumCover) => (
  cover.coverType.value === 'leather-cover'
    ? isLeatherCoverConfigured(cover)
    : isHardCoverConfigured(cover)
);
