import Layout from 'src/layout/Layout';
import styles from './index.module.scss';

const NotFound = () => (
  <Layout hideOptions>
    <div className={styles.layout}>
      <div className={styles.layout__content}>
        <h1>404</h1>
        <p>Pagina pe care o cautai nu a fost gasita.</p>
      </div>
    </div>
  </Layout>
);

export default NotFound;
