import { TAction } from './types';

const initialState = {
  focusedSectionUserImage: '',
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export const focusedSectionUserImageReducer = (state = initialState, action: TAction) => {
  switch (action.type) {
    case 'SET_FOCUSED_SECTION_USER_IMAGE':
      return {
        ...state,
        focusedSectionUserImage: action.payload,
      };

    case 'REMOVE_FOCUSED_SECTION_USER_IMAGE':
      return {
        ...state,
        focusedSectionUserImage: '',
      };

    default:
      return state;
  }
};
