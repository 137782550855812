/* eslint-disable react/jsx-props-no-spreading */
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store';

import firebase, { collections } from './firebase';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { createFirestoreInstance } from 'redux-firestore';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const RRFConfig = {
  firebase,
  config: collections,
  dispatch: store.dispatch,
  createFirestoreInstance,
};

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <ReactReduxFirebaseProvider {...RRFConfig}>
        <App />
      </ReactReduxFirebaseProvider>
    </Provider>
  </BrowserRouter>,
);
