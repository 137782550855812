import { isObject } from './isObject';
import { isObjectEmpty } from './isObjectEmpty';

export const removeUndefinedObjectValues = (value: any) => {
  if (!isObject(value) || isObjectEmpty(value)) return value;

  Object.keys(value).forEach((key) => {
    if (isObject(value[key])) {
      return removeUndefinedObjectValues(value[key]);
    }

    if (value[key] === undefined) {
      // eslint-disable-next-line no-param-reassign
      delete value[key];
    }

    return value[key];
  });

  return value;
};
