/* eslint-disable react/button-has-type */
import cn from 'classnames';
import styles from './index.module.scss';

import type { ReactNode } from 'react';

interface Props {
  className?: string;
  mode?: 'default' | 'alternative';
  children?: ReactNode;
  type?: 'button' | 'submit' | 'reset';
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  handleClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  filled?: boolean;
}

const Button = ({
  className,
  mode,
  children,
  type,
  filled,
  size,
  handleClick,
}: Props) => (
  <button
    className={cn([className, {
      [styles.button]: !filled,
      [styles['button--filled']]: filled,
      [styles.xs]: size === 'xs',
      [styles.sm]: size === 'sm',
      [styles.md]: size === 'md',
      [styles.lg]: size === 'lg',
      [styles.xl]: size === 'xl',
      [styles.alternative]: mode === 'alternative',
    }])}
    type={type}
    onClick={handleClick}
  >
    {children}
  </button>
);

Button.defaultProps = {
  children: undefined,
  handleClick: undefined,
  mode: 'default',
  size: 'md',
  className: '',
  type: 'button',
  filled: false,
};

export default Button;
