import { OPTIONS } from '../../config';
import { TAction } from './types';

const initialState = {
  option: OPTIONS.PICTURES,
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export const optionReducer = (state = initialState, action: TAction) => {
  switch (action.type) {
    case 'SET_SELECTED_OPTION':
      return {
        ...state,
        option: action.payload,
      };

    default:
      return state;
  }
};
