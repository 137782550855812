import { TAction } from './types';

const initialState = {
  selectedView: 'album-covers',
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export const selectedViewReducer = (state = initialState, action: TAction) => {
  switch (action.type) {
    case 'SET_SELECTED_VIEW':
      return {
        ...state,
        selectedView: action.payload,
      };

    default:
      return state;
  }
};
