/* eslint-disable max-len */
import type { AlbumConfigurationAvailableSize, AlbumCover, AlbumType } from 'src/types';

const getPriceForExtraOpenings = (album: AlbumType, sizeConfiguration: AlbumConfigurationAvailableSize): number => {
  const numOpenings = album.sections.length;

  const additionalOpenings = numOpenings - album.albumConfiguration.minNumberOpenings;
  const extraOpeningsPrice = additionalOpenings * sizeConfiguration.pricePerExtraOpening;

  return extraOpeningsPrice;
};

const getAlbumCoverSizeConfiguration = (album: AlbumType, cover: AlbumCover): AlbumConfigurationAvailableSize | undefined => {
  const coverChosenSizeId = cover?.albumSize?.value;

  return album.albumConfiguration?.availableSizes.find(
    (size) => size.myId === coverChosenSizeId,
  );
};

const getCoverPrice = (cover: AlbumCover, sizeConfiguration: AlbumConfigurationAvailableSize): number => {
  if (cover.coverType.value === 'hard-cover') return sizeConfiguration.coverPrice.hardCover;

  const leatherCoverPrice = 'leather' in sizeConfiguration.coverPrice
    ? sizeConfiguration.coverPrice.leather
    : sizeConfiguration.coverPrice.leatherCover;

  const leatherCoverInsertSize = cover.photoInsertConfiguration?.size.value || 'no-insert';

  const photoInsertPrice = leatherCoverInsertSize !== 'no-insert'
    ? sizeConfiguration.photoInsertPrice[leatherCoverInsertSize]
    : 0;

  return (leatherCoverPrice as number) + photoInsertPrice;
};

export const getAlbumPrice = (album: AlbumType, cover: AlbumCover): number => {
  if (!cover) return 0;

  const sizeConfiguration = getAlbumCoverSizeConfiguration(album, cover);

  let extraOpeningsPrice = 0;
  let coverPrice = 0;
  let albumPrice = 0;

  if (sizeConfiguration) {
    extraOpeningsPrice = getPriceForExtraOpenings(album, sizeConfiguration);
    coverPrice = getCoverPrice(cover, sizeConfiguration);
    albumPrice = sizeConfiguration.startingPrice + extraOpeningsPrice + coverPrice;
  }

  const finalPrice = albumPrice * cover.numberOfAlbumsToOrder;

  if (cover.hasDiscountCode) {
    if (finalPrice - cover.discountCode.amount <= 0) return 0;

    return finalPrice - cover.discountCode.amount;
  }

  return finalPrice;
};
