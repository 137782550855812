/* eslint-disable no-nested-ternary */
import {
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { FirebaseFallbackImage } from '../../../../../../../../../../../components';

type TemplateUserImageProps = {
  x: number | string;
  y: number | string;
  z: number | string;
  width: number | string;
  height: number | string;
  imageUrl?: string;
  rotationDeg?: number;
  firebaseImageUrl?: string;
  targetWidth?: number;
  targetHeight?: number;
  targetRotation?: number;
  top?: number;
  left?: number;
  xScale?: number;
  yScale?: number;
};

const SectionImage = ({
  x,
  y,
  z,
  width,
  height,
  imageUrl = '',
  firebaseImageUrl = '',
  rotationDeg,
  left,
  top,
  targetHeight,
  targetRotation,
  targetWidth,
  xScale,
  yScale,
}: TemplateUserImageProps) => {
  const [imageComputedStyles, setImageComputedStyles] = useState({
    transform: '',
  });

  const containerRef = useRef<HTMLDivElement | null>(null);
  const imageRef = useRef<HTMLImageElement | null>(null);

  const computedStyles = useMemo(
    () => ({
      left: `${x}%`,
      top: `${y}%`,
      width: `${width}%`,
      height: `${height}%`,
      transform: `rotate(${rotationDeg}deg)`,
      zIndex: Number(z) - 10000,
    }),
    [height, rotationDeg, width, x, y, z],
  );

  const xRatio = useMemo(() => {
    if (!imageRef.current) return 100;

    return (imageRef.current.clientWidth * 100) / Number(targetWidth);
  }, [targetWidth]);

  const yRatio = useMemo(() => {
    if (!imageRef.current) return 100;

    return (imageRef.current.clientHeight * 100) / Number(targetHeight);
  }, [targetHeight]);

  const updateImageComputedStyles = useCallback(() => {
    let computedLeft = left ? ((left * xRatio) / 100) : 0;
    let computedTop = top ? ((top * yRatio) / 100) : 0;

    if (imageRef.current && containerRef.current) {
      const centerX = imageRef.current.offsetLeft + imageRef.current.offsetWidth / 2;
      const centerY = imageRef.current.offsetTop + imageRef.current.offsetHeight / 2;

      const centerX2 = containerRef.current.offsetWidth / 2;
      const centerY2 = containerRef.current.offsetHeight / 2;

      const leftOffset = centerX - centerX2;
      const topOffset = centerY - centerY2;

      const leftOffsetAsPercentage = ((leftOffset / imageRef.current.offsetWidth) * 100);
      const topOffsetAsPercentage = ((topOffset / imageRef.current.offsetHeight) * 100);

      if (computedTop === 0) {
        computedTop = -topOffsetAsPercentage / 2;
      }

      if (computedLeft === 0) {
        computedLeft = -leftOffsetAsPercentage / 2;
      }
    }

    setImageComputedStyles({
      transform: `rotate(${targetRotation}deg) scale(${xScale}, ${yScale}) translate(${computedLeft}%, ${computedTop}%)`,
    });
  }, [
    left,
    top,
    targetRotation,
    xRatio,
    xScale,
    yRatio,
    yScale,
  ]);

  useEffect(() => {
    updateImageComputedStyles();

    window.addEventListener('resize', updateImageComputedStyles);

    return () => {
      window.removeEventListener('resize', updateImageComputedStyles);
    };
  }, [
    left,
    top,
    targetRotation,
    xRatio,
    xScale,
    yRatio,
    yScale,
  ]);

  return (
    <div
      className={styles['user-input-image-container']}
      style={computedStyles}
    >
      <div
        className={styles['section-image-wrapper']}
        ref={containerRef}
      >
        <FirebaseFallbackImage
          key={`section_image_${imageUrl}_${firebaseImageUrl}`}
          localImageUrl={imageUrl}
          firebaseImageUrl={firebaseImageUrl}
          stringifiedStyles={JSON.stringify(imageComputedStyles)}
          emitRef={(elm: HTMLImageElement | null) => {
            imageRef.current = elm;
          }}
          handleImageLoad={() => {
            updateImageComputedStyles();
          }}
        />
      </div>
    </div>
  );
};

SectionImage.propTypes = {
  x: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  y: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  z: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  imageUrl: PropTypes.string,
  firebaseImageUrl: PropTypes.string,
  rotationDeg: PropTypes.number,
  targetWidth: PropTypes.number,
  targetHeight: PropTypes.number,
  targetRotation: PropTypes.number,
  top: PropTypes.number,
  left: PropTypes.number,
  xScale: PropTypes.number,
  yScale: PropTypes.number,
};

SectionImage.defaultProps = {
  imageUrl: '',
  firebaseImageUrl: '',
  rotationDeg: 0,
  targetWidth: 0,
  targetHeight: 0,
  targetRotation: 0,
  top: 0,
  left: 0,
  xScale: 1,
  yScale: 1,
};

export default SectionImage;
