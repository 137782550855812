import PropTypes from 'prop-types';
import { PropsWithChildren } from 'react';
import styles from './index.module.scss';

interface Props {
  text?: string;
}

const SectionWithText = ({
  text,
  children,
}: PropsWithChildren<Props>) => (
  <div className={styles['minimap-section-container']}>
    {children}
    <span className={styles['section-text']}>{text}</span>
  </div>
);

SectionWithText.propTypes = {
  text: PropTypes.string,
};

SectionWithText.defaultProps = {
  text: '',
};

export default SectionWithText;
