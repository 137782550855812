/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import {
  useRef,
  useCallback,
  useMemo,
  useEffect,
  memo,
  useLayoutEffect,
} from 'react';
import PropTypes from 'prop-types';
import { useDebounce } from 'use-debounce';
import { useDrop } from 'react-dnd';
import styles from './index.module.scss';
import { ComputedImage } from './components';
import { ImageControls } from 'src/components';
import { useDispatch } from 'react-redux';
import { removeFocusedSection, setFocusedSection } from 'src/store/focusedSection/actions';
import Placeholder from 'src/public/images/image-placeholder.png';

import type { OnRotate, OnDrag, OnScale } from 'react-moveable';
import type { HandleImageDrag, HandleImageRotation, HandleImageScale } from 'src/types';

type TemplateImageProps = {
  id: string;
  sectionId: string;
  x: number | string;
  y: number | string;
  z: number | string;
  width: number | string;
  height: number | string;
  imageUrl: string;
  firebaseImageUrl: string;
  rotationDeg: number;
  targetWidth?: number;
  targetHeight?: number;
  targetRotation?: number;
  top: number;
  left: number;
  xScale: number;
  yScale: number;
  sectionElm: HTMLDivElement | null;
  isChangingTemplateText: boolean;
  isCurrentSectionInFocus: boolean;
  initialScaleDone: boolean;
  initialDragDone: boolean;
  initialRotateDone: boolean;
  updateSectionImage: ({
    sectionId,
    imageId,
    imageUrl,
    firebaseUrl,
    imageSettings,
  }: {
    sectionId: string;
    imageId: string;
    imageUrl?: string;
    firebaseUrl?: string;
    imageSettings: any & {
      initialScaleDone?: boolean;
      initialRotateDone?: boolean;
      initialDragDone?: boolean;
    }
  }) => void;
  handleImageDrag: HandleImageDrag;
  handleImageScale: HandleImageScale;
  handleImageRotation: HandleImageRotation;
};

const TemplateImage = ({
  id,
  x,
  y,
  z,
  width,
  height,
  imageUrl = '',
  firebaseImageUrl = '',
  sectionId,
  rotationDeg,
  left,
  top,
  targetHeight,
  targetRotation,
  targetWidth,
  xScale,
  yScale,
  sectionElm,
  isCurrentSectionInFocus,
  isChangingTemplateText,
  updateSectionImage,
  handleImageDrag,
  handleImageRotation,
  handleImageScale,
  initialScaleDone,
  initialRotateDone,
  initialDragDone,
}: TemplateImageProps) => {
  const templateImageSectionRef = useRef<HTMLDivElement | null>(null);
  const imageRef = useRef<HTMLImageElement | null>(null);

  const dispatch = useDispatch();

  const [{ isOver }, drop] = useDrop(() => ({
    accept: 'image',
    drop: (item: any) => {
      updateSectionImage({
        sectionId,
        imageId: id,
        imageUrl: item?.image,
        firebaseUrl: item?.firebaseUrl,
        imageSettings: {
          initialScaleDone: false,
          initialDragDone: false,
          initialRotateDone: false,
        },
      });

      dispatch(setFocusedSection(''));
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }), [id, sectionId]);

  const hasImage = useMemo(() => (!!imageUrl || !!firebaseImageUrl), [imageUrl, firebaseImageUrl]);

  const handleDrag = useCallback(
    ({
      transform,
      translate,
    }: OnDrag) => {
      if (!imageRef.current) return;

      const translateAsPercentage = [
        (translate[0] / imageRef.current.offsetWidth) * 100,
        (translate[1] / imageRef.current.offsetHeight) * 100,
      ];

      handleImageDrag({
        id: sectionId,
        imageId: id,
        transform,
        translate: translateAsPercentage,
      });
    },
    [id, sectionId, imageRef.current],
  );

  const handleScale = useCallback(
    ({ transform, scale }: OnScale) => {
      handleImageScale({
        id: sectionId,
        imageId: id,
        transform,
        scale,
      });
    },
    [id, sectionId],
  );

  const handleRotate = useCallback(
    ({ transform, rotation }: OnRotate) => {
      handleImageRotation({
        id: sectionId,
        imageId: id,
        transform,
        rotation,
      });
    },
    [id, sectionId],
  );

  const computedStyles = useMemo(
    () => ({
      left: `${x}%`,
      top: `${y}%`,
      width: `${width}%`,
      height: `${height}%`,
      zIndex: Number(z) - 100,
      opacity: isOver ? 0.5 : 1,
      transform: `rotate(${rotationDeg}deg)`,
      overflow: 'hidden',
    }),
    [
      height,
      isOver,
      rotationDeg,
      width,
      x,
      y,
      z,
    ],
  );

  const [xRatio, yRatio] = useMemo(() => {
    if (!imageRef.current || !targetWidth || !targetHeight) {
      return [100, 100];
    }

    const {
      clientWidth,
      clientHeight,
    } = imageRef.current;

    const widthRatio = (clientWidth * 100) / targetWidth;
    const heightRatio = (clientHeight * 100) / targetHeight;

    return [widthRatio, heightRatio];
  }, [imageRef.current, targetWidth, targetHeight]);

  const [handleResizeWithDebounce] = useDebounce(() => {
    dispatch(removeFocusedSection());

    const computedLeft = left ? ((left * xRatio) / 100) : 0;
    const computedTop = top ? ((top * yRatio) / 100) : 0;

    updateSectionImage({
      sectionId,
      imageId: id,
      imageSettings: {
        targetRotation,
        xScale,
        yScale,
        left: computedLeft,
        top: computedTop,
      },
    });
  }, 200);

  useEffect(() => {
    window.addEventListener('resize', handleResizeWithDebounce);

    return () => {
      window.removeEventListener('resize', handleResizeWithDebounce);
    };
  }, [
    left,
    top,
    targetRotation,
    xRatio,
    xScale,
    yRatio,
    yScale,
  ]);

  const showImageControls = useMemo(() => (
    hasImage
      && isCurrentSectionInFocus
      && !isChangingTemplateText
      && !!sectionElm
      && !!imageRef.current
  ), [hasImage, isCurrentSectionInFocus, isChangingTemplateText, sectionElm, imageRef.current]);

  const showImage = useMemo(() => (
    hasImage && !!sectionElm
  ), [hasImage, sectionElm]);

  // Rotate image to the same degree as the container
  useLayoutEffect(() => {
    if (initialRotateDone) return;

    updateSectionImage({
      sectionId,
      imageId: id,
      imageSettings: {
        targetRotation: 0,
        initialRotateDone: true,
      },
    });
  }, [initialRotateDone, imageRef.current]);

  // Scale image to fit container's dimensions
  useLayoutEffect(() => {
    if (initialScaleDone || !initialRotateDone) return;

    if (!imageRef.current || !templateImageSectionRef.current) return;

    const imageElm = imageRef.current;
    const containerElm = templateImageSectionRef.current;

    const imageWidth = imageElm.offsetWidth;
    const imageHeight = imageElm.offsetHeight;

    const containerWidth = containerElm.offsetWidth;
    const containerHeight = containerElm.offsetHeight;

    const widthRatio = containerWidth / imageWidth;
    const heightRatio = containerHeight / imageHeight;

    const ratio = Math.max(widthRatio, heightRatio);

    updateSectionImage({
      sectionId,
      imageId: id,
      imageSettings: {
        xScale: ratio,
        yScale: ratio,
        initialScaleDone: true,
      },
    });
  }, [initialScaleDone, imageRef.current, sectionElm]);

  // Drag image to center of container
  useLayoutEffect(() => {
    if (initialDragDone || !initialScaleDone) return;

    if (!imageRef.current || !templateImageSectionRef.current) return;

    const imageComputedWidth = imageRef.current.offsetWidth * xScale;
    const imageComputedHeight = imageRef.current.offsetHeight * yScale;

    const offsetXFactor = (1.0 - xScale) / 2;
    const offsetYFactor = (1.0 - yScale) / 2;

    const centerX = imageRef.current.offsetWidth * offsetXFactor + (imageComputedWidth / 2);
    const centerY = imageRef.current.offsetHeight * offsetYFactor + (imageComputedHeight / 2);

    const centerX2 = (templateImageSectionRef.current.offsetWidth / 2);
    const centerY2 = (templateImageSectionRef.current.offsetHeight / 2);

    const leftOffset = Math.abs(centerX - centerX2);
    const topOffset = Math.abs(centerY - centerY2);

    const offsetXModifier = centerX > centerX2 ? -1 : 1;
    const offsetYModifier = centerY > centerY2 ? -1 : 1;

    let leftOffsetAsPercentage = (100 / (imageComputedWidth / leftOffset)) * offsetXModifier;
    let topOffsetAsPercentage = (100 / (imageComputedHeight / topOffset)) * offsetYModifier;

    if (Math.round(centerX) === Math.round(centerX2)) {
      leftOffsetAsPercentage = 0;
    }

    if (Math.round(centerY) === Math.round(centerY2)) {
      topOffsetAsPercentage = 0;
    }

    updateSectionImage({
      sectionId,
      imageId: id,
      imageSettings: {
        left: leftOffsetAsPercentage,
        top: topOffsetAsPercentage,
        initialDragDone: true,
      },
    });
  }, [initialDragDone, imageRef.current, initialScaleDone, xScale, yScale, initialRotateDone]);

  const handleSetImageRef = useCallback((img: HTMLImageElement | null) => {
    if (img) {
      imageRef.current = img;
    }
  }, []);

  return (
    <>
      <div
        style={computedStyles}
        className={styles['user-input-image-container']}
        ref={templateImageSectionRef}
      >
        {showImageControls && !!imageRef.current && (
          <ImageControls
            key={`image-controls-${sectionId}_${id}`}
            targetImage={imageRef.current}
            sectionElm={sectionElm as any}
            handleDrag={handleDrag}
            handleScale={handleScale}
            handleRotate={handleRotate}
          />
        )}
        {!hasImage && (
          <img
            style={{
              opacity: (!isOver && !hasImage) ? '1' : '0.5',
            }}
            className={styles.placeholder}
            src={Placeholder}
          />
        )}
        {showImage && (
          <ComputedImage
            key={`computed-image-${sectionId}_${id}`}
            left={left}
            top={top}
            targetRotation={targetRotation}
            xScale={xScale}
            yScale={yScale}
            imageUrl={imageUrl}
            firebaseImageUrl={firebaseImageUrl}
            setImageRef={handleSetImageRef}
          />
        )}
      </div>
      <div
        ref={drop}
        style={{
          ...computedStyles,
          zIndex: isOver
            ? 1000000000000
            : '',
        }}
        className={styles['shadow-container']}
      />
    </>
  );
};

TemplateImage.propTypes = {
  x: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  y: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  z: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  imageUrl: PropTypes.string,
  firebaseImageUrl: PropTypes.string,
  rotationDeg: PropTypes.number,
  targetWidth: PropTypes.number,
  targetHeight: PropTypes.number,
  targetRotation: PropTypes.number,
  top: PropTypes.number,
  left: PropTypes.number,
  xScale: PropTypes.number,
  yScale: PropTypes.number,
};

TemplateImage.defaultProps = {
  imageUrl: '',
  firebaseImageUrl: '',
  rotationDeg: 0,
  targetWidth: 0,
  targetHeight: 0,
  targetRotation: 0,
  top: 0,
  left: 0,
  xScale: 1,
  yScale: 1,
};

export default memo(TemplateImage);
