import cn from 'classnames';
import styles from './index.module.scss';

interface Props {
  className?: string;
  type: string;
  placeholder?: string;
  required?: boolean;
  name?: string;
  value?: string;
  min?: number;
  disabled?: boolean;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const TextInput = ({
  type,
  className,
  placeholder,
  name,
  value,
  size,
  required,
  disabled,
  min,
  onChange,
}: Props) => (
  <input
    className={cn([className, styles.input, {
      [styles.xs]: size === 'xs',
      [styles.sm]: size === 'sm',
      [styles.md]: size === 'md',
      [styles.lg]: size === 'lg',
      [styles.xl]: size === 'xl',
    }])}
    value={value}
    type={type}
    name={name}
    disabled={disabled}
    required={required}
    placeholder={placeholder}
    onChange={onChange}
    min={min}
  />
);

TextInput.defaultProps = {
  value: '',
  placeholder: undefined,
  required: false,
  disabled: false,
  className: '',
  size: 'md',
  name: undefined,
  onChange: undefined,
  min: undefined,
};

export default TextInput;
