import { TAction } from './types';

const initialState = {
  focusedSection: '',
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export const focusedSectionReducer = (state = initialState, action: TAction) => {
  switch (action.type) {
    case 'SET_FOCUSED_SECTION':
      return {
        ...state,
        focusedSection: action.payload,
      };

    case 'REMOVE_FOCUSED_SECTION':
      return {
        ...state,
        focusedSection: '',
      };

    default:
      return state;
  }
};
