import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { romanianTranslations } from './ro';
import { englishTranslations } from './en';

export const resources = {
  ro: {
    translation: romanianTranslations,
  },
  en: {
    translation: englishTranslations,
  },
} as const;

export const defaultNS = 'translation';

i18n
  .use(initReactI18next)
  .init({
    resources,
    defaultNS,
    lng: process.env.REACT_APP_LANGUAGE?.toLowerCase(),
    fallbackLng: 'ro',
    interpolation: {
      escapeValue: false,
    },
    keySeparator: '.',
    ns: 'translation',
  });

export default i18n;
