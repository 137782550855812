import { UploadedImagesActions } from './constants';
import { TAction } from './types';

const initialState: { uploadedImages: any[] } = {
  uploadedImages: [],
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export const uploadedImagesReducer = (state = initialState, action: TAction) => {
  switch (action.type) {
    case UploadedImagesActions.UPLOAD_IMAGE:
      return {
        ...state,
        uploadedImages: [
          ...state.uploadedImages,
          action.payload,
        ],
      };

    case UploadedImagesActions.REMOVE_IMAGE:
      return {
        ...state,
        uploadedImages: state.uploadedImages.filter((image) => image.id !== action.payload.id),
      };

    default:
      return state;
  }
};
