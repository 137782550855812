import { FocusedSectionActions } from './constants';

export const setFocusedSection = (focusedSectionId: string) => ({
  type: FocusedSectionActions.SET_FOCUSED_SECTION,
  payload: focusedSectionId,
});

export const removeFocusedSection = () => ({
  type: FocusedSectionActions.REMOVE_FOCUSED_SECTION,
});
