import type { TranslationStructure } from 'src/types';

export const englishTranslations: TranslationStructure = {
  home: {
    welcomeHeader: 'Welcome!',
    welcomeParagraph: 'Studio adidume is the place where passion is being combined with excellency and it gives birth to albums you will be reminiscing dearly upon over years.',
    form: {
      header: 'Photo Albums',
      name: 'Name',
      phone: 'Phone Number',
      albumType: 'Album Type',
      albumDesign: 'Album Design',
      shippingAddress: 'Shipping Address',
      actionButton: 'Configure',
    },
  },
  layout: {
    navbar: {
      buttons: {
        share: 'Share',
        order: 'Order',
        save: 'Save',
      },
      messages: {
        albumNotConfiguredError: 'Make sure all covers and pages have been configured!',
        namelessAlbumsError: 'Make sure all covers have a person\'s name!',
        atLeastOneAlbumError: 'Make sure everyone will order a valid number of albums!',
        sizelessAlbumsError: 'Make sure that all albums have a size assigned!',
        orderError: 'There was an issue ordering the albums',
        orderConfirmationModalText: 'Are you sure you want to order now?',
        copyToClipboard: 'Link was copied to Clipboard.',
      },
    },
    controlsSidebar: {
      galleryHeader: 'Gallery',
      actions: {
        addPhotos: 'Add Images',
      },
      loadingText: 'Loading',
      switch: {
        covers: 'Album Covers',
        interior: 'Album Interior',
      },
    },
    overviewSidebar: {
      albumInformation: {
        header: 'Albums Information',
        numNumberOpenings: 'Num. of min. openings/album',
        maxNumberOpenings: 'Num. of max openings/album',
        numConfiguredOpenings: 'Num. of configured openings',
        minNumberAlbums: 'Num. of min albums to order',
        numConfiguredCovers: 'Num. configured covers',
      },
      panelViews: {
        total: 'Total Price',
        templates: 'Templates',
      },
    },
  },
  titleCaseCoverText: 'Cover',
  titleCaseName: 'Name',
  titleCaseSectionPagesCountText: 'Pages',
  loadingText: 'Loading...',
  content: {
    coverToolbarInputs: {
      name: 'Name',
      albumSize: 'Album Size',
      coverType: 'Cover Type',
      discountCode: 'Discount Code',
    },
    leatherCoverContent: {
      sizeInput: 'Size',
      ecoLeatherColor: 'Eco Leather Color',
      photoInsert: {
        actions: {
          coverImage: 'Cover Image',
        },
      },
    },
  },
  confirmationMessages: {
    coverRemoval: 'Are you sure you want to remove this cover?',
    sectionRemoval: 'Are you sure you want to remove this section?',
  },
  actions: {
    addCoverTemplate: 'Choose Cover Template',
    addSectionTemplate: 'Choose a Template',
    addNewCover: 'Add a New Cover',
    addNewSection: 'Add a New Section',
  },
  modals: {
    chooseSectionTemplate: 'Choose Section Template',
    chooseLeatherColor: 'Choose Your Favorite Color',
    chooseCoverTemplate: 'Choose Cover Template',
    confirmationAffirmative: 'Yes',
    confirmationNegative: 'No',
  },
};
