/* eslint-disable max-len */
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { useNavigate } from 'react-router-dom';
import { useQuery } from './useQuery';
import { mapAlbumConfigurationFromFirestore } from 'src/utils/mapAlbumConfigurationFromFirestore';

import type { TAppState } from 'src/store';
import type { AlbumConfiguration, AlbumConfigurationAvailableDesign, FirestoreAlbumConfiguration } from 'src/types';

export const useRetrieveLinkCodeConfiguration = () => {
  // eslint-disable-next-line max-len
  const [albumConfiguration, setAlbumConfiguration] = useState<AlbumConfiguration | null>(null);
  const [albumDesign, setAlbumDesign] = useState<AlbumConfigurationAvailableDesign | null>(null);

  const queryParams = useQuery();
  const navigate = useNavigate();

  const code = queryParams.get('code');

  useFirestoreConnect([{
    collection: 'album-configurations',
  }]);

  const {
    firestore: {
      data: {
        'album-configurations': albumConfigurations,
      },
      status: {
        requesting: {
          'album-configurations': isLoadingAlbumConfigurations,
        },
      },
    },
  } = useSelector((state: TAppState) => state);

  useEffect(() => {
    if (!code) {
      navigate('/not-found');
    }
  }, [code]);

  useEffect(() => {
    if (!albumConfigurations || isLoadingAlbumConfigurations) return;

    // eslint-disable-next-line max-len
    const foundAlbumConfiguration: FirestoreAlbumConfiguration | null = Object.values(albumConfigurations).find(
      (configuration: any) => configuration.code === code,
    ) as FirestoreAlbumConfiguration | null;

    if (!foundAlbumConfiguration) {
      const foundAlbumConfigurationByDesign = Object.values?.(albumConfigurations)
        ?.map((configuration: any) => mapAlbumConfigurationFromFirestore(configuration))
        ?.find((config) => config.availableDesigns.find((design) => design?.code === code));

      if (!foundAlbumConfigurationByDesign) {
        navigate('/not-found');
        return;
      }

      const foundAlbumDesign = foundAlbumConfigurationByDesign?.availableDesigns
        .find((design) => design?.code === code) as AlbumConfigurationAvailableDesign;

      if (!foundAlbumDesign) {
        navigate('/not-found');
        return;
      }

      setAlbumDesign(foundAlbumDesign);
      setAlbumConfiguration(foundAlbumConfigurationByDesign);
    }

    if (foundAlbumConfiguration) {
      setAlbumConfiguration(mapAlbumConfigurationFromFirestore(foundAlbumConfiguration));
    }
  }, [albumConfigurations, isLoadingAlbumConfigurations]);

  return {
    loading: isLoadingAlbumConfigurations,
    albumConfiguration,
    albumDesign,
  };
};
