export enum AlbumActions {
  CREATE_ALBUM = 'CREATE_ALBUM',
  UPDATE_ALBUM = 'UPDATE_ALBUM',
  REMOVE_ALBUM = 'REMOVE_ALBUM',
  ADD_NEW_SECTION = 'ADD_NEW_SECTION',
  ADD_NEW_EMPTY_SECTION = 'ADD_NEW_EMPTY_SECTION',
  SETUP_EMPTY_SECTIONS = 'SETUP_EMPTY_SECTIONS',
  REMOVE_SECTION = 'REMOVE_SECTION',
  UPDATE_SECTION = 'UPDATE_SECTION',
  UPDATE_SECTION_IMAGE = 'UPDATE_SECTION_IMAGE',
  ADD_ALBUM_COVER = 'ADD_ALBUM_COVER',
  UPDATE_ALBUM_COVER = 'UPDATE_ALBUM_COVER',
  UPDATE_ALBUM_COVER_IMAGE = 'UPDATE_ALBUM_COVER_IMAGE',

  ADD_NEW_EMPTY_ALBUM_COVER = 'ADD_NEW_EMPTY_ALBUM_COVER',
  UPDATE_COVER = 'UPDATE_COVER',
  REMOVE_COVER = 'REMOVE_COVER',
  SETUP_EMPTY_COVERS = 'SETUP_EMPTY_COVERS',

  SET_INSERT_SIZE = 'SET_PHOTO_INSERT_SIZE',
  SET_INSERT_IMAGE = 'SET_PHOTO_INSERT_IMAGE',
  UPDATE_INSERT_PHOTO_CONFIGURATION = 'UPDATE_INSERT_PHOTO_CONFIGURATION',

  UPDATE_SECTION_TEXT_REGION_CONTENT = 'UPDATE_SECTION_TEXT_REGION_CONTENT',
  UPDATE_COVER_TEXT_REGION_CONTENT = 'UPDATE_COVER_TEXT_REGION_CONTENT',

  ATTACH_DISCOUNT_CODE = 'ATTACH_DISCOUNT_CODE',
  REMOVE_DISCOUNT_CODE = 'REMOVE_DISCOUNT_CODE',
}
